import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  tooltiptext: {
    width: "100%",
    backgroundColor: "#525252c9",
    color: "#fff",
    padding: "5px 0",
    position: "absolute",
    left: 0,
    right: 0,
    zIndex: 1,
    top: "50%",
    transform: "translate(0,-50%)",
    borderWidth: "1px",
    borderRadius: "6px",
    borderStyle: "hidden",
  },
  tooltipClose: {
    backgroundColor: "rgba(82, 82, 82, 1)",
    borderRadius: "50%",
    width: "25px",
    height: "25px",
    color: "rgb(255, 255, 255)",
    padding: "5px 0px",
    position: "absolute",
    right: "-2%",
    zIndex: 1,
    top: 0,
    transform: "translate(0px, -50%)",
    cursor: "pointer",
  },
  closeBtn: {
    fontSize: "18px",
    position: "absolute",
    right: 0,
    left: 0,
    textAlign: "center",
    margin: "0 auto",
    top: "50%",
    transform: "translate(0,-50%)",
  },
});

export default function Tooltip({ children, handleClose }) {
  const classes = useStyles();
  return (
    <span className={classes.tooltiptext}>
      {children}
      <span className={classes.tooltipClose}>
        <CloseIcon onClick={handleClose} className={classes.closeBtn} />
      </span>
    </span>
  );
}
