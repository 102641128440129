const styles = (theme) => ({
  root: {
    flexGrow: 1,
    margin: "20px 0px",
  },
  noMargin: { margin: "0px !important" },
  patientContainer: {},
  transitionEase: {
    transition: "all 0.2s ease-in 0.5s",
  },
  patientInnerContainer: {
    width: "100%",
    margin: "20px -15px",
  },
  mainScrollContainer: {
    padding: "0px 15px 0 15px",
    // minHeight: 'calc(100vh - 123px)'
  },
  personalDetails: {
    flexGrow: 0,
    width: "50%",
    flexBasis: "50%",
    padding: "0 15px 0 0",
    transition: "all 0.5s ease-in 0.8s",
    "&:nth-child(4n+2)": {
      padding: 0,
    },
    "&:nth-child(4n+4)": {
      padding: 0,
    },
  },
  personalDetailsFullWidth: {
    width: "100%",
    flexBasis: "100%",
    transition: "all 0.5s ease-in",
    padding: 0,
  },
  agGridMain: {
    flexGrow: 0,
    maxWidth: "100%",
    flexBasis: "100%",
    padding: "0 0 0 0",
  },
  personalDetailsInner: {
    color: "rgba(0, 0, 0, 0.87)",
    width: "100%",
    border: 0,
    display: "flex",
    position: "relative",
    minWidth: 0,
    wordWrap: "break-word",
    fontSize: ".875rem",
    marginTop: 20,
    background: "#fff",
    boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
    marginBottom: 30,
    borderRadius: 6,
    flexDirection: "column",
  },
  personalDetailsHeader: {
    borderRadius: "calc(.25rem - 1px) calc(.25rem - 1px) 0 0",
  },
  personalIconBox: {
    float: "left",
    padding: 15,
    marginTop: -20,
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 3,
    background: "linear-gradient(to right bottom, #299dca, #29b09b)",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4)",
  },
  detailsTitle: {
    color: "#3C4858",
    marginTop: 10,
    minHeight: "auto",
    fontWeight: 300,
    marginBottom: 0,
    textDecoration: "none",
  },
  personalDetailsContent: {
    flex: "1 1 auto",
    padding: 15,
    position: "relative",
  },
  programBias: {
    minHeight: 349,
    padding: 0,
  },
  textField: {
    width: "100%",
  },
  svgIcon: {
    width: 24,
    height: 24,
    margin: "5px 4px 0px",
    textAlign: "center",
    lineHeight: 33,
  },
  actionBtn: {
    fontSize: 34,
  },

  submitBtn: {
    padding: "15px 50px",
    letterSpacing: 1,
  },
  formControl: {
    width: "100%",
  },
  tableRoot: {
    width: "100%",
    overflowX: "auto",
  },
  tableTitle: {
    fontSize: "1.5rem",
    fontWeight: 400,
    padding: "4px 0",
    border: "none",
  },
  tableInnerTr: {
    color: "rgba(0, 0, 0, 0.54)",
    textAlign: "left",
    fontSize: 16,
    padding: "4px 0",
    border: "none",
  },
  tableHead: {
    width: "100%",
    display: "flex",
  },
  tablebody: {
    maxHeight: 300,
    overflow: "auto",
    display: "flex",
    flexWrap: "wrap",
  },
  tablerow: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
  },
  patientsAssessments: {
    maxWidth: "100%",
    flexBasis: "100%",
    marginTop: 20,
  },
  personalDetailsAssessments: {
    maxWidth: "50%",
    flexBasis: "50%",
  },
  pizzaSection: {
    maxWidth: "25%",
    flexBasis: "25%",
  },
  planSection: {
    maxWidth: "100%",
    flexBasis: "100%",
    transition: "all 0.5s ease-in",
    padding: "0 0 0 15px",
  },
  planSectionFullWidth: {
    maxWidth: "100%",
    flexBasis: "100%",
    transition: "all 0.5s ease-in",
  },

  patientsAssessmentsInner: {
    color: "rgba(0, 0, 0, 0.87)",
    width: "100%",
    border: 0,
    display: "flex",
    position: "relative",
    minWidth: 0,
    wordWrap: "break-word",
    fontSize: ".875rem",
    marginTop: 30,
    background: "#fff",
    boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
    marginBottom: 30,
    borderRadius: 6,
    flexDirection: "column",
  },
  gridContainer: {
    marginTop: 50,
  },
  personalMobile: {
    justifyContent: "center",
    flexDirection: "row",
  },
  btnBoxMain: {
    flexDirection: "column",
    width: "auto",
  },

  ///iocn style
  iconsMainContainer: {
    position: "absolute",
    right: 20,
    top: 86,
    width: "auto",
    zIndex: 99,
    cursor: "pointer",
  },
  assessmentsIcon: {
    right: 55,
  },
  saveIcon: {
    right: 98,
  },
  saveBtn: {
    backgroundColor: "#4CAF50",
    "&:hover": {
      backgroundColor: "#2E7D32",
    },
  },
  addPatients: {
    right: 15,
  },
  print: {
    right: 55,
  },
  share: {
    right: 15,
  },

  //Stage style

  stageMain: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  stage: {
    width: "100%",
    // margin: '25px 10px 10px'
    marginTop: 15,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  programStage: {
    width: "100%",
    alignItems: "center",
  },
  unitContainer: {
    width: "100%",
    justifyContent: "center",
  },
  unitTitle: {
    margin: 0,
  },
  unitBox: {
    display: "flex",
    marginLeft: 50,
  },
  btnTitle: {
    padding: 0,
    minWidth: 18,
    fontSize: 18,
    minHeight: 30,
  },
  btnActive: {
    borderBottom: "1px solid",
    borderRadius: 0,
  },
  typeBtn: {
    display: "flex",
    flexDirection: "row",
  },
  pc: {
    marginBottom: 5,
  },
  customGrid: {
    width: "12.5%",
    padding: 5,
  },
  //Exercise box
  switchGroup: {
    width: "auto",
    margin: "0px 0px 0 0",
    alignItems: "center",
  },
  switchBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    margin: `3px 12px`,
  },
  switchTitle: {
    marginTop: 0,
  },
  paper: {
    padding: 10,
    marginBottom: 10,
    position: "relative",
  },
  paperActive: {
    border: "1px solid rgba(11, 158, 164, 0.6)",
  },
  exerciseScrollingCommon: {
    minHeight: 154,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  exerciseImage: {
    width: 120,
    height: 120,
  },
  palnImgBox: {
    height: "100%",
    border: "1px solid #e6e6e6",
    padding: 5,
    borderRadius: 3,
    margin: "0 auto",
    cursor: "pointer",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    overflow: "hidden",
    position: "relative",
  },
  programInnerTitle: {
    position: "absolute",
    top: 5,
    right: 0,
    left: 5,
    textAlign: "left",
    color: "#908989de",
    fontWeight: 400,
    fontSize: 18,
  },
  exerciseScroll: {
    // maxHeight: 700,
    overflow: "auto",
    width: "100%",
    // marginTop: 40,
  },
  exerciseMain: {
    padding: "4px",
    overflow: "auto",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  exerciseMainDragDrop: {
    padding: "25px 20px",
    alignItems: "center",
    overflow: "auto",
  },
  resetBtnBox: {
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "space-between",
    padding: `10px 10px 0 10px`,
    whiteSpace: "nowrap",
  },
  exerciseList: {
    // marginTop: 20,
    justifyContent: "left",
  },
  categoryText: {
    textTransform: "uppercase",
    alignSelf: "flex-start",
  },
  marginBottom: {
    marginBottom: 20,
  },
  marginTop: {
    margin: "30px 0",
  },
  marginZero: {
    marginTop: 0,
  },
  activeExercise: {
    border: "1px solid #0b9ea4 ",
  },
  closeBtn: {
    height: 20,
    width: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    cursor: "pointer",
    backgroundColor: "#F5F5F5",
    borderRadius: "50%",
    zIndex: 10,
  },
  exerciseBtn: {
    "&.MuiTypography-root": {
      fontSize: "0.7rem",
      lineHeight: "normal",
    },
  },

  //Patient card list style
  patientCardList: {
    padding: "0px 20px 20px",
  },
  patientCardRoot: {
    flexGrow: 1,
    fontSize: 16,
  },
  patientCardContainer: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    cursor: "pointer",
  },
  patientCardHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  patientCardUserSection: {
    display: "flex",
  },
  patientCardAvatar: {
    fontSize: "1.5em",
    fontWeight: 400,
    lineHeight: 1.17,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#0b9ea4",
    width: "2.6em",
    height: "2.6em",
    alignSelf: "center",
    letterSpacing: 1,
  },
  patientCardUser: {
    fontSize: "1.2em",
    wordBreak: "break-all",
    color: "rgba(0, 0, 0, 0.87)",
  },
  patientCardMail: {
    marginTop: 0,
    fontSize: "1em",
    wordBreak: "break-all",
    color: "rgba(0, 0, 0, 0.54)",
  },
  patientCardDetailsBlock: {
    margin: "20px 0px 10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  patientCardDetailsRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    borderBottom: "1px solid rgba(0, 0, 0, 0.14)",
    "&:last-child": {
      borderBottom: 0,
    },
  },
  patientCardDetailItem: {
    fontSize: "0.98em",
    padding: "0px 0px 10px",
    color: "rgba(0, 0, 0, 0.87)",
    "&:last-child": {
      padding: "0px 10px 0px 10px",
    },
  },
  patientCardDetailLabel: {
    fontWeight: 500,
    color: "rgba(0, 0, 0, 0.87)",
  },
  extendedIconSpin: {
    marginRight: theme.spacing(1),
    animation: "spin 2s infinite linear",
  },
  reSubBtn: {
    color: "#fff",
    backgroundColor: "#0b9ea4",
    "&:hover": {
      backgroundColor: "rgb(7, 110, 114)",
    },
  },
  cancelSubBtn: {
    color: "#fff",
    backgroundColor: "#ff4d4d",
    "&:hover": {
      backgroundColor: "#bf2525",
    },
  },

  // Popup style
  titleBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  closeIcon: {
    marginRight: 20,
    cursor: "pointer",
    color: "#9E9E9E",
    fontSize: 20,
  },
  description: {
    padding: "15px 25px",
  },
  btnBox: {
    margin: "15px 4px",
  },

  // Card Style
  cardRoot: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    border: "1px solid rgba(0, 0, 0, 0.26)",
  },
  cardInput: {
    marginLeft: 8,
    flex: 1,
  },
  expDate: {
    padding: 10,
    color: "rgba(0, 0, 0, 0.38)",
    fontFamily: '"Roboto", sans-serif',
    fontSize: 16,
    fontWeight: 300,
  },
  cardDivider: {
    width: 1,
    height: 28,
    margin: 4,
  },

  // Common style

  icon: {
    margin: theme.spacing(2),
  },

  // Pre screen
  prescreenRoot: {
    paddingRight: "15px",
    paddingLeft: "15px",
    marginRight: "auto",
    marginLeft: "auto",
  },
  prescreenContainer: {
    margin: "20px 0px",
    padding: theme.spacing(3),
  },
  prescreenHeader: {
    marginBottom: 20,
    padding: 12,
    borderBottom: "3px solid #0b9ea4",
  },
  prescreenSubHeader: {
    marginBottom: 10,
    padding: 8,
    textAlign: "left",
    lineHeight: "normal",
  },
  problemWrapper: {
    boxShadow:
      "0px 1px 3px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 2px 1px -1px rgba(0,0,0,0.12)",
    borderRadius: "4px",
    padding: "20px",
    height: "630px",
    maxHeight: "630px",
    marginBottom: "10px",
    overflow: "auto",
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    flexBasis: "auto",
    flexShrink: 0,
    textTransform: "uppercase",
    display: "flex",
    alignItems: "center",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  fab: {
    margin: theme.spacing(1),
  },
  bodyParts: {
    width: "100%",
    display: "block",
  },
  tableMainBox: {
    display: "flex",
    justifyContent: "space-between",
  },
  tableScroll: {
    maxWidth: "calc(100vw - 153px)",
    overflow: "auto",
  },
  assessmentPanelDetails: {
    padding: "0 12px",
  },
  assessmentPanelDetailsWithColumn: {
    padding: "0 12px",
    flexDirection: "column",
  },
  assessmentPanelSummary: {
    padding: "0 12px",
    minHeight: "46px !important",
  },
  assessmentAccordion: {
    boxShadow: "none",
    width: "100%",
  },
  assessmentPanelSummaryContent: {
    margin: "6px 0 !important",
    justifyContent: "space-between",
  },
  assessmentMuiButtonBase: {
    backgroundColor: "red",
  },
  popover: {
    pointerEvents: "none",
  },
  popoverPaper: {
    padding: theme.spacing(2),
    color: "#00",
  },
  // prescreenPizza: {
  //   maxWidth: '100%',
  //   flexBasis: '100%',
  //   margin:'0 !important'
  // },
  // expensionPanelPadding: {
  //   padding: '8px 24px 0px'
  // },
  templatesBtn: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 20,
  },
  templateBtnCenter: {
    textAlign: "center",
  },
  programBtnBox: {
    width: "80%",
    flexDirection: "row",
  },
  programStageMain: {
    flexDirection: "row",
  },
  programCategoryBtn: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  programTablePadding: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  pizzaSizeChange: {
    maxWidth: "50%",
  },
  programPizzaSize: {
    maxWidth: "71.6%",
  },
  fullHeight: {
    height: "100%",
  },
  panelHeading: {
    fontSize: theme.typography.pxToRem(18),
    // borderBottom: '1px solid #ccc',
    flexBasis: "70%",
    flexShrink: 0,
  },
  textarea: {
    fontSize: 18,
    fontWeight: 300,
    boxSizing: "border-box",
    border: "none",
    // borderTop: '1px solid #ccc',
    resize: "none",
    outline: "none",
    lineHeight: "20px !important",
    // overflow: "auto",
    width: "100%",
    height: "auto",
    padding: "0 2px 0 0",
    // fontSize: '1.0714285714285714rem',
    fontFamily: "Roboto, sans-serif",
    // boxShadow: '0px 4px 10px -8px black',
    // marginBottom: '10px',
    "&:focus": {
      outline: "none",
    },
  },
  scrollRightSideForm: {
    maxHeight: 720,
    // padding: '0px 20px',
    overflowX: "hidden",
    overflowY: "auto",
  },
  removeMargin: {
    marginTop: 0,
  },
  //drawarea graph style
  drawArea: {
    width: "100%",
    height: "100%",
    border: "1px solid #D4D9DD",
    // cursor: "crosshair",
    // backgroundImage: 'url(/images/ROM-Graph/flexibility2.svg)',
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "100%",
    touchAction: "none",
  },
  typesBtn: {
    padding: 8,
    width: "100%",
    marginRight: 10,
    "&:last-child": {
      marginRight: 0,
    },
  },
  graphTypesBtn: {
    display: "flex",
  },
  commentBoxHeight: {
    minHeight: 217.5, //previous: 326.5
  },
  objectiveCollapsibleMargin: {
    marginTop: "0px !important",
  },
  objectiveCollapsiblePadding: {
    paddingTop: "0px",
    paddingBottom: 0,
    marginBottom: 8,
  },
  drawAreaHeight: {
    height: 280,
  },
  // graphTypesSetBtn: {                                       //OLD CSS
  //   display: 'flex',
  //   flexDirection: 'column'
  // },
  // typesSetBtn: {
  //   marginBottom: 10,
  //   "&:last-child": {
  //     marginBottom: 0
  //   }
  // },

  graphTypesSetBtn: {
    //NEW CSS
    display: "flex",
    flexDirection: "row",
    marginLeft: 0,
  },
  // typesSetBtn: {
  //   margin: "12px 10px 10px 0",
  //   width: "100%",
  //   "&:last-child": {
  //     margin: "12px 0 10px 0"
  //   }
  // },
  textArea: {
    backgroundColor: "transparent",
    border: 0,
    width: "auto",
    wordWrap: "break-word",
    maxWidth: 160,
    textAlign: "left",
    fontSize: 16,
    // position: "relative",
    cursor: "text",
    "&:focus": {
      outlineColor: "transparent",
      outlineStyle: "auto",
      outlineWidth: 0,
    },
  },

  textFieldComments: {
    width: "100%",
    marginTop: -23,
    "& textarea": {
      minHeight: "147px !important",
    },
    exerciseSwitch: {
      flexDirection: "row",
    },
  },

  //Media style
  "@media (max-width: 1600px)": {
    detailsTitle: {
      fontSize: "1.4988rem",
    },
    typeBtn: {
      flexDirection: "column",
    },
    pizzaSection: {
      maxWidth: "25%",
      flexBasis: "25%",
      padding: 8,
    },
    prescreenPizza: {
      maxWidth: "50%",
      flexBasis: "50%",
    },
    planSection: {
      maxWidth: "100%",
      flexBasis: "100%",
      padding: 8,
    },

    customGrid: {
      width: "12.5%",
    },
    customGridFullWidth: {
      width: "10%",
    },
    drawAreaMargin: {
      marginTop: 4,
    },
    actionButtonMargin: {
      marginBottom: 25,
    },
  },
  "@media (max-width: 1440px)": {
    detailsTitle: {
      fontSize: "1.158rem",
    },
    personalMobile: {
      justifyContent: "center",
      flexDirection: "row",
    },
    btnBoxMain: {
      width: "auto",
    },
    customGrid: {
      width: "12.5%",
    },
    customGridFullWidth: {
      width: "10%",
    },
    drawAreaMargin: {
      marginTop: 25,
    },
    graphTypesSetBtn: {
      display: "flex",
      flexDirection: "row",
    },
    patientCardRoot: {
      fontSize: 12,
    },
    pizzaSizeChange: {
      maxWidth: "72%",
    },
    commentBoxHeight: {
      minHeight: 217.5,
      marginBottom: -11,
      paddingTop: 24,
    },
    textFieldComments: {
      "& textarea": {
        minHeight: "147px !important",
      },
    },
    actionButtonMargin: {
      marginBottom: 25,
    },
  },
  "@media (max-width: 1380px)": {
    commentBoxHeight: {
      minHeight: 544.5,
    },
  },
  "@media (max-width: 1366px)": {
    btnBoxMain: {
      flexDirection: "column",
    },
    // planSectionHeight: {
    //   minHeight: '640px'
    // },
    exerciseScroll: {
      minHeight: "525px",
    },
    customGridFullWidth: {
      width: "12.5%",
    },
    commentBoxHeight: {
      minHeight: 235.5,
    },
    graphTypesSetBtn: {
      display: "flex",
      flexDirection: "row",
    },
    textFieldComments: {
      "& textarea": {
        minHeight: "147px !important",
      },
    },
    // programBias: {
    //   minHeight: 598,
    // },
  },
  "@media (max-width: 1280px)": {
    problemWrapper: {
      height: "550px",
    },
    pizzaSizeChange: {
      maxWidth: "60%",
    },
    btnBoxMain: {
      flexDirection: "column",
    },
    commentBoxHeight: {
      minHeight: 303.5,
      paddingTop: 0,
    },
    textFieldComments: {
      marginTop: 0,
      "& textarea": {
        minHeight: "205px !important",
      },
    },
    actionButtonMargin: {
      marginBottom: 8,
    },
  },

  "@media (max-width: 1100px)": {
    problemWrapper: {
      height: "480px",
    },
    unitBox: {
      margin: 0,
    },
    iconsMainContainer: {
      top: 105,
    },
    textFieldComments: {
      marginTop: 0,
      "& textarea": {
        minHeight: "225px !important",
      },
    },
  },
  "@media (max-width: 1024px)": {
    exerciseSwitch: {
      flexDirection: "row",
    },
    problemWrapper: {
      height: "420px",
    },
    prescreenSubHeader: {
      fontSize: "1rem",
    },
    patientsAssessmentsInner: {
      width: "100%",
    },
    programBias: {
      minHeight: 372,
    },

    unitContainer: {
      width: "100%",
      justifyContent: "center",
    },
    patientInnerContainer: {
      margin: 0,
    },
    mainScrollContainer: {
      padding: 0,
    },
    iconsMainContainer: {
      top: 105,
    },
    personalDetailsAssessments: {
      maxWidth: "100%",
      flexBasis: "100%",
    },
    pizzaSection: {
      maxWidth: "50%",
      flexBasis: "50%",
    },
    prescreenPizza: {
      maxWidth: "100%",
      flexBasis: "100%",
    },
    planSection: {
      maxWidth: "100%",
      flexBasis: "100%",
    },
    // planSectionHeight: {
    //   minHeight: '640px'
    // },
    exerciseScroll: {
      minHeight: "525px !important",
    },
    customGrid: {
      width: "25%",
    },
    customGridFullWidth: {
      width: "14.28%",
    },
    pc: {
      width: "14.28%",
    },
    programInnerTitle: {
      fontSize: 16,
    },
    bodyMapBox: {
      width: "100%",
      height: "40%",
    },
    heading: {
      fontSize: "1rem",
      // fontWeight: 500,
      flexBasis: "auto",
    },
    personalDetailsInner: {
      marginBottom: 30,
    },
    commentBoxHeight: {
      minHeight: 235,
    },
    exerciseBtn: {
      fontSize: "0.72rem",
    },
    btnBoxMain: {
      flexDirection: "column",
    },
    programBtnBoxMain: {
      order: "2",
    },
    programBoxMain: {
      flexDirection: "row",
    },
    panelHeading: {
      fontSize: "1rem",
    },
    textarea: {
      fontWeight: 300,
    },
    stage: {
      order: "3",
      width: "100%",
      textAlign: "center",
      justifyContent: "center",
      marginTop: 10,
    },
    programStage: {
      width: "100%",
      marginTop: 10,
      order: "3",
    },
    scrollRightSideForm: {
      maxHeight: 540,
    },
    graphTypesSetBtn: {
      display: "flex",
      flexDirection: "row",
      marginLeft: 20,
    },
    pizzaSizeChange: {
      order: "1",
      maxWidth: "71.6%",
    },
    textFieldComments: {
      marginTop: 12,
      "& textarea": {
        minHeight: "135px !important",
      },
    },
    actionButtonMargin: {
      marginBottom: 15,
    },
    exerciseScrollingCommon: {
      minHeight: 158,
    },
    exerciseImage: {
      width: "100%",
      height: "100%",
    },
  },
  "@media (max-width: 960px)": {
    detailsTitle: {
      fontSize: "1.3rem",
    },
  },
  "@media (max-width: 900px)": {
    stage: {
      // order: "0",
      width: "20%",
      marginTop: 0,
    },
    commentBoxHeight: {
      minHeight: 306,
    },
    graphTypesSetBtn: {
      display: "flex",
      flexDirection: "row",
      marginLeft: 0,
      justifyContent: "space-around",
    },
    programStage: {
      order: "1",
    },
    programBtnBoxMain: {
      order: "3",
    },
    problemWrapper: {
      height: "320px",
    },
    programBias: {
      minHeight: 340,
    },
    personalDetails: {
      maxWidth: "100%",
      flexBasis: "100%",
      padding: 0,
    },
    patientInnerContainer: {
      margin: 0,
      marginTop: 0,
    },
    pizzaSection: {
      maxWidth: "100%",
      flexBasis: "100%",
    },
    planSection: {
      maxWidth: "100%",
      flexBasis: "100%",
      padding: 0,
    },
    customGrid: {
      width: "16.6666667%",
    },
    tableScroll: {
      maxWidth: "calc(100vw - 80px)",
    },
    btnBoxMain: {
      flexDirection: "column",
    },
    pizzaSizeChange: {
      maxWidth: "50%",
      width: "100%",
      textAlign: "center",
    },
    programPizzaSize: {
      maxWidth: "42.5%",
      order: "2",
    },
    heading: {
      flexBasis: "auto",
    },
    scrollRightSideForm: {
      height: 420,
    },
    textFieldComments: {
      marginTop: 14,
      "& textarea": {
        minHeight: "178px !important",
      },
    },
  },
  "@media (max-width: 768px)": {
    PrescreenSubmitBtn: {
      padding: "15px 50px",
      letterSpacing: 1,
      width: 140,
    },
    commentBoxHeight: {
      minHeight: 266,
      paddingTop: 24,
    },
    textFieldComments: {
      marginTop: -8,
      "& textarea": {
        minHeight: "167px !important",
      },
    },
    exerciseSwitch: {
      flexDirection: "row",
    },
    biasCenter: {
      justifyContent: "center",
    },
    drawAreaMargin: {
      marginTop: 0,
    },
    actionButtonMargin: {
      marginBottom: 6,
    },
  },
  "@media (max-width: 600px)": {
    stage: {
      order: "3",
      width: "100%",
      marginTop: 10,
    },
    scrollRightSideForm: {
      // padding: '20px 8px 20px'
    },
    personalMobile: {
      justifyContent: "center",
    },
    iconsMainContainer: {
      top: 112,
    },
    palnImgBox: {
      width: "100%",
    },
    exerciseList: {
      width: "100%",
    },
    exerciseScroll: {
      width: "100%",
    },
    btnBoxMain: {
      flexDirection: "row",
    },
    exerciseMain: {
      padding: "25px 20px",
    },
    // patientCardList: {
    //   padding: '80px 10px'
    // },
    tableMainBox: {
      alignItems: "center",
    },
    pizzaSizeChange: {
      maxWidth: "100%",
    },
    programPizzaSize: {
      maxWidth: "100%",
    },
    heading: {
      flexBasis: "auto",
    },
    switchGroup: {
      position: "relative",
    },
    printIconBtn: {
      position: "absolute",
      right: "35px",
      top: 12,
    },
    drawArea: {
      width: "100%",
    },
    personalDetailsInner: {
      marginBottom: 20,
    },
    textFieldComments: {
      marginTop: -33,
      "& textarea": {
        minHeight: "140px !important",
      },
    },
    commentBoxHeight: {
      minHeight: "auto",
    },
  },
  "@media (max-width: 595px)": {
    customGrid: {
      width: "50%",
    },
    pc: {
      width: "50%",
    },
  },
  "@media (max-width: 567px)": {
    detailsTitle: {
      fontSize: "1.2rem",
    },
    iconsMainContainer: {
      position: "inherit",
      background: "#fff",
      padding: 5,
      boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
    },
    patientInnerContainer: {
      margin: 0,
      marginTop: -20,
    },
    programInnerTitle: {
      fontSize: 14,
    },
    graphTypesSetBtn: {
      marginLeft: 0,
      flexDirection: "row",
    },
    ROMHeaderMargin: {
      marginLeft: 10,
    },
    patientContainer: { marginTop: 40 },
  },
  "@media (max-width: 320px)": {
    tableMainBox: {
      alignItems: "flex-start",
      flexDirection: "column",
    },
  },
});
export default styles;
