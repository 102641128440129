import React from 'react'
import Typography from '@mui/material/Typography';

const NoPage = (props) => {
    return (
        <>
            <Typography variant="h2" gutterBottom component="div" style={{ textAlign: 'center' }}>
                404
            </Typography>
            <Typography variant="h6" gutterBottom component="div" style={{ textAlign: 'center' }}>
                No such page found
            </Typography>
        </>
    )
}

export default NoPage
