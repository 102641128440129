export const DefaultLayout = {
    header: {
        display: false,
    },
    sidebar: {
        display: false,
    },
    footer: {
        display: false,
    },
}