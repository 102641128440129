import React, { useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { Link, useLocation } from "react-router-dom";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useDispatch } from "react-redux";
import ListItemButton from "@mui/material/ListItemButton";
import Collapse from "@mui/material/Collapse";
import { PowerSettingsNew } from "@mui/icons-material";
import { logoutUser } from "store/authSlice";

const SideBarItems = (props) => {
  const dispatch = useDispatch();
  const allItems = [
    { label: "Dashboard", icon: <DashboardIcon />, location: "/dashboard" },
    // { label: "Settings", icon: <Settings />, location: "/settings" },
    {
      label: "Logout",
      icon: <PowerSettingsNew />,
      fx: () => {
        dispatch(logoutUser());
      },
      location: "/login",
    },
  ];
  const [open, setOpen] = useState("");

  const location = useLocation();
  const handleClick = (index) => {
    setOpen(index);
  };

  return (
    <List>
      {allItems.map((el, index) =>
        el.children ? (
          <div key={index}>
            <ListItemButton
              onClick={() => handleClick(index)}
              selected={location.pathname === el.location}
              classes={{ selected: "active" }}
            >
              <ListItemIcon>{el.icon}</ListItemIcon>
              <ListItemText primary={el.label} />
              {open === index ||
              el.children.find(
                (subel) => subel.location === location.pathname
              ) ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )}
            </ListItemButton>

            <Collapse
              in={
                open === index ||
                el.children.find(
                  (subel) => subel.location === location.pathname
                )
              }
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {el.children.map((subEl, i = index) => {
                  return (
                    <ListItem
                      button
                      sx={{ pl: 4 }}
                      component={Link}
                      to={subEl.location}
                      key={i}
                      selected={location.pathname === subEl.location}
                      classes={{ selected: "active" }}
                    >
                      <ListItemIcon>{subEl.icon}</ListItemIcon>
                      <ListItemText primary={subEl.label} />
                    </ListItem>
                  );
                })}
              </List>
            </Collapse>
          </div>
        ) : (
          <ListItem
            button
            component={Link}
            to={el.location}
            key={index}
            selected={location.pathname === el.location}
            classes={{ selected: "active" }}
            onClick={() => el.fx && el.fx()}
          >
            <ListItemIcon>{el.icon}</ListItemIcon>
            <ListItemText primary={el.label} />
          </ListItem>
        )
      )}
    </List>
  );
};

export default SideBarItems;
