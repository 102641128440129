import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { useLocation, useNavigate } from "react-router-dom";
import layouts from "config/layouts";
import Header from "./header/header";
import SideBar from "./sidebar/SideBar";
import Main from "./main/main";
import { DefaultLayout } from "config/layouts/defaultLayout";
import Toast from "components/SnackBar/SnackBar";
import { checkAuth } from "store/authSlice";
import { styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";

const drawerWidth = 240;
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function Layout(props) {
  const [open, setOpen] = React.useState(false);
  let location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let currentLayout = DefaultLayout;

  const path =
    location.pathname.lastIndexOf("/") === location.pathname.length - 1
      ? location.pathname.slice(0, location.pathname.length - 1)
      : location.pathname;

  layouts.forEach((layout) => {
    if (layout.route.path === path || path.includes(layout.route.path)) {
      currentLayout = layout.layout;
    }
  });

  React.useEffect(() => {
    const isAuthenticated = dispatch(checkAuth());
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [navigate, dispatch]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {currentLayout.header.display ? (
        <Header
          drawerWidth={drawerWidth}
          open={open}
          handleDrawerOpen={handleDrawerOpen}
        />
      ) : (
        ""
      )}
      {currentLayout.sidebar.display ? (
        <SideBar
          drawerWidth={drawerWidth}
          open={open}
          handleDrawerClose={handleDrawerClose}
        />
      ) : (
        ""
      )}
      <Main open={open} drawerWidth={drawerWidth}>
        {currentLayout.sidebar.display ? <DrawerHeader /> : null}
        <div style={{ padding: currentLayout.sidebar.display ? "24px" : "0" }}>
          {props.children}
        </div>
      </Main>
      <Toast />
    </Box>
  );
}
