import { Button, Grid } from "@mui/material";
import React from "react";
import Category from "../Assessment/Category";
import Pizza from "../Assessment/Pizza";
import Stage from "../Assessment/Stage";
import Unit from "../Assessment/Unit";
import commonStyles from "styles/CommonStyle";
import { combineStyles } from "utils";
import { withStyles } from "@mui/styles";

const styles = (theme) => ({
  commonAccordionMain: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  mainContainer: {
    margin: "20px 0",
  },
  headerText: {
    "&.MuiTypography-root": {
      fontSize: 20,
    },
  },
  panelSummary: {
    justifyContent: "space-between",
    alignItems: "center",
    "&.MuiAccordionSummary-root.Mui-expanded": {
      minHeight: 48,
    },
    "&.MuiAccordionSummary-content.Mui-expanded": {
      margin: "12px 0",
    },
  },
  "@media (max-width: 767px)": {
    headerText: {
      fontSize: 16,
    },
  },
  commonBoxLayout: {
    padding: 15,
    background: "linear-gradient(to right bottom, #299dca, #29b09b)",
    boxShadow:
      "0 4px 20px 0px rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(233 30 99 / 40%)",
    marginTop: "-40px",
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 3,
    display: "flex",
  },
  commonIconBox: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    "&.MuiButtonBase-root": { fontSize: "0.675rem", lineHeight: "normal" },
  },
  stage: { display: "flex", fontSize: "0.6rem", justifyContent: "center" },
});

function BiasSection(props) {
  const {
    // isAddExercise,
    classes = {},
    templates = [],
    state = {},
    updateBias = () => {},
    validGroupList = [],
    invalidGroupList = [],
    handleStageClick = () => {},
    handleUnitClick = () => {},
    setTemplate = () => {},
    handleCategoryChange = () => {},
  } = props;
  let {
    stage = [],
    bias = [],
    stagesBias,
    exerciseCategories,
    selectedCategory,
    unit,
  } = state;
  return (
    <>
      <Grid
        className={[classes.personalDetailsContent, classes.programBias].join(
          " "
        )}
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          className={classes.personalMobile}
        >
          <Grid
            container
            spacing={2}
            item
            justify="center"
            xl={2}
            lg={3}
            md={2}
            sm={2}
          >
            {templates.map((template) => {
              const { _id, title } = template;
              return (
                <Grid
                  key={_id}
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  // xs={6}
                  className={classes.templateBtnCenter}
                >
                  <Button
                    color={
                      state.selectedTemplate === title ? "primary" : "inherit"
                    }
                    style={{
                      width: "100%",
                      padding: 8,
                      minHeight: 44,
                    }}
                    className={classes.label}
                    onClick={() => {
                      setTemplate(template);
                      // updateState({ selectedTemplate: title });
                    }}
                  >
                    {title}
                  </Button>
                </Grid>
              );
            })}
          </Grid>
          <Grid
            item
            xl={7}
            lg={6}
            md={7}
            sm={7}
            xs={12}
            className={classes.programPizzaSize}
          >
            <Pizza
              stage={stage}
              bias={bias}
              stagesBias={stagesBias}
              updateBias={updateBias}
              validGroupList={validGroupList}
              invalidGroupList={invalidGroupList}
            />
          </Grid>

          <Grid
            container
            item
            xl={3}
            lg={3}
            md={3}
            sm={3}
            xs={12}
            className={[classes.btnBoxMain, classes.programBtnBoxMain].join(
              " "
            )}
            spacing={2}
          >
            {exerciseCategories &&
              Object.keys(exerciseCategories).map((category, index) => (
                <Category
                  key={category + index}
                  name={category}
                  selected={
                    selectedCategory.includes(category) ? "primary" : undefined
                  }
                  badge={exerciseCategories[category].length}
                  handleCategoryChange={handleCategoryChange}
                  prescreenMode
                />
              ))}
          </Grid>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={classes.stage}
          >
            <Stage activeStage={stage} handleClick={handleStageClick} />
            <Unit activeUnit={unit} handleClick={handleUnitClick} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default withStyles(combineStyles(styles, commonStyles))(BiasSection);
