import React from "react";
import update from "immutability-helper";
import { connect } from "react-redux";
import Grid from "@mui/material/Grid";
import withStyles from "@mui/styles/withStyles";
import Card from "./Card";
import styles from "styles/CommonStyle";

const Treatment = ({
  classes,
  selectedExercises,
  onUpdate,
  onRemove,
  imageMode,
}) => {
  const moveCard = (dragIndex, hoverIndex) => {
    const dragCard = selectedExercises[dragIndex];
    const updateedData = update(selectedExercises, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragCard],
      ],
    });
    onUpdate(updateedData);
  };
  const removeCard = (index) => {
    const updateedData = update(selectedExercises, { $splice: [[index, 1]] });
    onRemove(updateedData);
  };
  return (
    <Grid container className={classes.exerciseScroll}>
      {/* <Grid container className={classes.exerciseMainDragDrop}> */}
      {selectedExercises.map((card, i) => (
        <Card
          key={card._id}
          index={i}
          moveCard={moveCard}
          removeCard={removeCard}
          imageMode={imageMode}
          {...card}
        />
      ))}
      {/* </Grid> */}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  appointment: state?.common?.collapseStack?.appointment || {},
});

export default connect(mapStateToProps, null)(withStyles(styles)(Treatment));
