import Dashboard from "./Dashboard";
import authRoles from "core/auth/authRole";
const DashboardConfig = {
  route: {
    path: "/dashboard",
    element: <Dashboard />,
   
  },


  layout: {
    header: {
      display: true,
    },
    sidebar: {
      display: true,
    },
    footer: {
      display: false,
    },
  },
  auth: authRoles.admin,
};

export default DashboardConfig;
