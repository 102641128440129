import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import React from "react";

function PopupDialog(props) {
  const {
    open = false,
    onClose = () => {},
    onCancel = () => {},
    onAction = () => {},
    title = "",
    actionTitle = "",
    actionBg = "",
  } = props;
  const actionColor = actionBg
    ? { style: { backgroundColor: actionBg, color: "white" } }
    : { color: "primary" };
  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogContent>{title}</DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button onClick={onAction} {...actionColor} variant="contained">
            {actionTitle}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default PopupDialog;
