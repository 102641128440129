import React, { useRef } from "react";
// import { DragSource, DropTarget } from "react-dnd";
import { connect } from "react-redux";
import clsx from "clsx";
import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import CloseIcon from "@mui/icons-material/Close";
// import { detectmob } from '../../../../utils/GlobalFunctions';
import styles from "styles/CommonStyle";

const style = {
  padding: "5px",
  backgroundColor: "white",
  cursor: "move",
  position: "relative",
};

const Card = React.forwardRef(
  (
    {
      classes,
      appointment,
      isOver,
      isDragging,
      connectDragPreview,
      connectDragSource,
      connectDropTarget,
      moveRow,
      dragRow,
      clientOffset,
      sourceClientOffset,
      initialClientOffset,
      imageMode,
      index,
      removeCard,
      name,
      file,
      springLoad,
      // ...restProps
    },
    ref
  ) => {
    const elementRef = useRef(null);
    // connectDragSource(elementRef);
    // connectDropTarget(elementRef);
    // useImperativeHandle(ref, () => ({
    //   getNode: () => elementRef.current,
    // }));
    return (
      <React.Fragment>
        <div
          ref={elementRef}
          style={Object.assign(
            {},
            style,
            isOver
              ? {
                  backgroundColor: "pink",
                }
              : {}
          )}
          className={clsx(classes.customGrid, classes.customGridFullWidth)}
        >
          <span
            className={classes.closeBtn}
            style={{
              right: imageMode ? "-4px" : "-6px",
              top: imageMode ? "-4px" : "-4px",
            }}
            onClick={() => removeCard(index)}
          >
            <CloseIcon
              color="primary"
              style={{
                fontSize: 16,
              }}
            />
          </span>
          {imageMode && (
            <Paper
              className={clsx(
                classes.paper,
                classes.exerciseScrollingCommon,
                classes.paperActive
              )}
              style={{ marginBottom: 0, padding: 5, height: 160 }}
            >
              <div style={{ width: 120, height: 120 }}>
                <img
                  alt={name}
                  src={file}
                  style={{
                    width: "100%",
                    height: "100%",
                    pointerEvents: "none",
                    display: "block",
                    objectFit: "contain",
                  }}
                />
              </div>{" "}
              {springLoad && (
                <Typography
                  variant="body1"
                  className={classes.programInnerTitle}
                >
                  {springLoad}
                </Typography>
              )}
              <Typography
                color="primary"
                className={classes.exerciseBtn}
                style={{ textAlign: "center" }}
                variant="body1"
              >
                {name}
              </Typography>
            </Paper>
          )}
          {!imageMode && (
            <Button
              variant="outlined"
              color={"primary"}
              className={[classes.button].join(" ")}
              classes={{ label: classes.exerciseBtn }}
              style={{
                minWidth: "100%",
                textTransform: "none",
                minHeight: 56,
                padding: "5px",
              }}
            >
              {name}
            </Button>
          )}
        </div>
      </React.Fragment>
    );
  }
);

// const rowSource = {
//   beginDrag: (props) => ({
//     id: props.id,
//     index: props.index,
//   }),
// };

// const rowTarget = {
//   drop(props, monitor) {
//     const dragIndex = monitor.getItem().index;
//     const hoverIndex = props.index;

//     // Don't replace items with themselves
//     if (dragIndex === hoverIndex) {
//       return;
//     }

//     // Time to actually perform the action
//     props.moveCard(dragIndex, hoverIndex);

//     // Note: we're mutating the monitor item here!
//     // Generally it's better to avoid mutations,
//     // but it's good here for the sake of performance
//     // to avoid expensive index searches.
//     monitor.getItem().index = hoverIndex;
//   },
// };
const mapStateToProps = (state) => ({
  appointment: state?.common?.collapseStack?.appointment || {},
});

export default connect(
  mapStateToProps,
  null
)(
  withStyles(styles)(
    // DropTarget(ItemTypes.CARD, rowTarget, (connect, monitor) => ({
    //   connectDropTarget: connect.dropTarget(),
    //   isOver: monitor.isOver(),
    //   sourceClientOffset: monitor.getSourceClientOffset(),
    // }))(
    //   DragSource(ItemTypes.CARD, rowSource, (connect, monitor) => ({
    //     connectDragSource: connect.dragSource(),
    //     connectDragPreview: connect.dragPreview(),
    //     isDragging: monitor.isDragging(),
    //     dragRow: monitor.getItem(),
    //     clientOffset: monitor.getClientOffset(),
    //     initialClientOffset: monitor.getInitialClientOffset(),
    //   }))
    // )
    Card
  )
);
