import React from "react";
import { AppProvider } from "context/AppContext";
import { Provider } from "react-redux";
import { BrowserRouter, useRoutes } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import routes from "config/routes";
import theme from "config/theme";
import Layout from "core/Layout";
import store from "store";
import ErrorBoundry from "components/ErrorBoundry/ErrorBoundry";

function Routes() {
  return useRoutes(routes);
}

export default function App() {
  return (
    <AppProvider
      value={{
        routes,
      }}
    >
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <ErrorBoundry>
              <Layout>
                <Routes />
              </Layout>
            </ErrorBoundry>
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    </AppProvider>
  );
}
