import { createSlice } from "@reduxjs/toolkit";
import { http } from "utils";
import { showMessage } from "./snackBarSlice";
import axios from "axios";

const USER_INFO = "userinfo";
const TOKEN = "token";

const initialState = {
  userInfo: {},
  loading: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loading: (state, action) => {
      state.loading = Boolean(action.payload);
    },
    login: (state, action) => {
      state.userInfo = action.payload;
    },
    logout: (state) => {
      state = initialState;
    },
  },
});

export const { loading, login, logout } = authSlice.actions;

export const checkAuth = () => (dispatch, getState) => {
  const token = getAccessToken();
  const userInfo = getUserInfo();

  if (userInfo?.role === "admin" && token) {
    dispatch(setUserInfo({ ...userInfo }));
    axios.interceptors.request.use(
      (config) => {
        config.headers["Authorization"] = `jwt ${token}`;
        return config;
      },
      (error) => {
        console.error(error);
        return Promise.reject(error);
      }
    );
    return true;
  }
  return false;
};

export const getAccessToken = (token) => {
  return localStorage.getItem(TOKEN);
};

export const storeAccessToken = (token) => {
  localStorage.setItem(TOKEN, token);

  axios.interceptors.request.use(
    (config) => {
      config.headers["Authorization"] = `jwt ${token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const removeAccessToken = (token) => {
  localStorage.removeItem(TOKEN);
};

export const loginUser = (reqBody, successCB) => (dispatch, getState) => {
  dispatch(loading(true));
  http("POST", "admin/login", reqBody)
    .then((res) => {
      const { data, token, code } = res;
      if (code === 200) {
        storeAccessToken(token);
        dispatch(setUserInfo({ ...data, role: "admin" }));
        dispatch(loading(false));
        successCB();
      } else {
        dispatch(
          showMessage({
            message: res.message || "Something went wrong",
            type: "error",
          })
        );
      }
    })
    .catch((err) => {
      dispatch(loading(false));
      dispatch(
        showMessage({
          message: err.message || "Invalid Credentials",
          type: "error",
        })
      );
    });
};

export const logoutUser = () => (dispatch, getState) => {
  removeAccessToken();
  removeUserInfo();
  dispatch(logout());
};

export const getUserInfo = () => {
  const userinfo = JSON.parse(localStorage.getItem(USER_INFO) || "{}");
  return userinfo;
};

export const setUserInfo = (userInfo) => (dispatch, getState) => {
  localStorage.setItem(USER_INFO, JSON.stringify(userInfo));
  dispatch(login(userInfo));
};

export const removeUserInfo = () => {
  localStorage.removeItem(USER_INFO);
};

// Action creators are generated for each case reducer function

export default authSlice.reducer;
