import React from "react";
import { connect } from "react-redux";
import includes from "lodash/includes";
import clsx from "clsx";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import withStyles from "@mui/styles/withStyles";
import styles from "styles/CommonStyle";
import { Delete, Edit } from "@mui/icons-material";
import { combineStyles } from "utils";

const internalStyles = (theme) => ({
  gridFlexColumn: {
    "&.MuiGrid-root": {
      display: "flex",
      flexDirection: "column",
    },
  },
  categoryMargin: {
    "&.MuiTypography-root": {
      marginBottom: 20,
    },
  },
  categoryMarginTop: {
    "&.MuiTypography-root": {
      marginTop: 20,
    },
  },
});

class ProgramExercise extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      items: this.props.selectedExercises || [],
    };
  }

  renderCategoryName(name) {
    switch (name) {
      case "MW":
        return "Matwork";
      case "REF":
        return "Reformer";
      case "TT":
        return "Trapeze";
      case "TB":
        return "Theratube";
      case "ACC":
        return "Accessories";
      default:
        return name;
    }
  }

  renderCategory = (exercises) => {
    const { classes, activeList, imageMode, appointment } = this.props;
    const result = [];
    const bias = includes(appointment, "bias");
    exercises.forEach((exercise, index) => {
      let { _id, file, name, springLoad } = exercise;
      const active = includes(activeList, exercise._id);
      if (springLoad) {
      }
      // const displayName = name.charAt(0) + name.slice(1).toLowerCase();
      // const subTitle =
      //   includes(category, 'REF') || includes(category, 'TB')
      //     ? name.substring(name.length - 2, name.length)
      //     : undefined;
      result.push(
        <Grid
          key={_id + index}
          className={clsx(classes.pc, classes.customGrid, {
            [classes.customGridFullWidth]: bias,
          })}
          style={{ position: "relative" }}
        >
          <span
            className={classes.closeBtn}
            style={{
              right: -4,
              top: -4,
            }}
            onClick={() => this.props.handleEditExercise(exercise)}
          >
            <Edit
              color="primary"
              style={{
                fontSize: 16,
              }}
            />
          </span>
          <span
            className={classes.closeBtn}
            style={{
              right: imageMode ? "-4px" : "-4px",
              bottom: 0,
            }}
            onClick={() => this.props.invokeDeletePopup(exercise)}
          >
            <Delete
              color="error"
              style={{
                fontSize: 16,
              }}
            />
          </span>
          {imageMode && (
            <Paper
              className={clsx(classes.paper, classes.exerciseScrollingCommon, {
                [classes.paperActive]: active,
              })}
              style={{ marginBottom: 0, padding: 5, height: 160 }}
            >
              {" "}
              <img
                alt={name}
                src={file}
                style={{ width: "100%", height: "100%", display: "block" }}
              />
              {springLoad && (
                <Typography
                  variant="body1"
                  className={classes.programInnerTitle}
                >
                  {springLoad}
                </Typography>
              )}
              <Typography
                color={"primary"}
                className={classes.exerciseBtn}
                style={{ textAlign: "center" }}
                variant="body1"
              >
                {name}
              </Typography>
            </Paper>
          )}
          {!imageMode && (
            <Button
              variant="outlined"
              color={active ? "primary" : undefined}
              className={[classes.button].join(" ")}
              classes={{ label: classes.exerciseBtn }}
              style={{
                minWidth: "100%",
                textTransform: "none",
                minHeight: 56,
                padding: "5px",
              }}
            >
              {name}
            </Button>
          )}
        </Grid>
      );
    });
    return result;
  };

  render() {
    const { classes, exerciseCategories, selectedCategory, treatmentMode } =
      this.props;
    const result = [];

    selectedCategory.forEach((category, index) => {
      result.push(
        <React.Fragment key={category}>
          <Typography
            variant="h6"
            color="primary"
            className={clsx(classes.categoryMargin, classes.categoryText, {
              [classes.categoryMarginTop]: index > 0,
            })}
          >
            {this.renderCategoryName(category)}
          </Typography>
          <Grid container spacing={1} className={classes.exerciseList}>
            {this.renderCategory(exerciseCategories[category])}
          </Grid>
        </React.Fragment>
      );
    });
    return (
      <Grid
        className={classes.exerciseScroll}
        style={{
          display: treatmentMode ? "none" : "block",
          // minHeight: 532,
        }}
      >
        <Grid
          container
          className={clsx(classes.exerciseMain, classes.gridFlexColumn)}
        >
          {result}
        </Grid>
      </Grid>
    );
  }
}

ProgramExercise.defaultProps = {
  assessmentMode: false, // set assessment mode default props
};

const mapStateToProps = (state) => ({
  appointment: state?.common?.collapseStack?.appointment || {},
});

export default connect(
  mapStateToProps,
  null
)(withStyles(combineStyles(styles, internalStyles))(ProgramExercise));
