import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
// import FormControl from '@mui/material/FormControl';
// import FormGroup from '@mui/material/FormGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import Button from "@mui/material/Button";
import includes from "lodash/includes";

const styles = (theme) => ({
  root: {
    display: "flex",
    // justifyContent: 'center',
    // alignItems: 'center'
    "@media (max-width: 1024px)": {
      justifyContent: "center",
    },
  },
  formGroup: {
    display: "flex",
    // justifyContent: 'center',
    // alignItems: 'center',
    flexDirection: "column",
    "@media (max-width: 1024px)": {
      flexDirection: "row",
    },
  },
  programFormGroup: {
    flexDirection: "row",
    justifyContent: "center",
  },
});

class Unit extends React.Component {
  handleChange = (event) => {
    this.props.handleClick(event.currentTarget.value);
  };
  render() {
    const { classes, activeUnit } = this.props;

    const unitA = includes(activeUnit, "A");
    const unitB = includes(activeUnit, "B");

    return (
      <div className={classes.root}>
        <Button
          color={unitA ? "primary" : "inherit"}
          onClick={(e) => this.handleChange(e)}
          style={{ minWidth: 38 }}
          value="A"
          variant="text"
        >
          A
        </Button>
        <Button
          color={unitB ? "primary" : "inherit"}
          onClick={(e) => this.handleChange(e)}
          style={{ minWidth: 38 }}
          value="B"
          variant="text"
        >
          B
        </Button>
        {/* <FormControl component="fieldset">
          <FormLabel component="legend">Assign responsibility</FormLabel> //Comment 
          <FormGroup className={[classes.formGroup, classes.programFormGroup].join(' ')}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={unitA}
                  onChange={this.handleChange}
                  value="A"
                  color="primary"
                />
              }
              label="Unit A"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={unitB}
                  onChange={this.handleChange}
                  value="B"
                  color="primary"
                />
              }
              label="Unit B"
            />
          </FormGroup>
        </FormControl> */}
      </div>
    );
  }
}

Unit.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(Unit);
