import React from "react";
import Player from "./Player";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import { withStyles } from "@mui/styles";
import { connect } from "react-redux";
import { Delete, VideoFile } from "@mui/icons-material";
import { deleteVideo } from "store/htvSlice";
import PopupDialog from "components/PopupDialog/PopupDialog";

const styles = (theme) => ({
  card: {
    width: "100%",
    minHeight: 180,
    height: "100%",
  },
  cardMainBox: {
    display: "flex",
    height: "100%",
    justifyContent: "space-between",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 270,
    width: "100%",
  },
  content: {
    flex: "1 0 auto",
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  title: {
    fontWeight: 200,
    display: "block",
    width: "100%",
    textAlign: "left",
  },
  cover: {
    width: "100%",
    maxWidth: "100%",
    minHeight: "100%",
    height: "100%",
    backgroundColor: "#0b9ea4",
  },
  noVideosFound: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 auto",
  },
  "@media (min-width: 1920px )": {
    videoDemo: {
      maxWidth: 400,
      flexBasis: 400,
    },
  },
});

class VideoGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPopupOpen: false,
      activeVideo: {
        title: "",
        url: "",
      },
      lastActiveIndex: null,
      deleteInfo: null,
    };
  }
  handleVideoClick = (index) => {
    this.setState({
      isPopupOpen: true,
      activeVideo: { ...this.props.howToVideos.videos[index] },
      lastActiveIndex: index,
    });
  };
  handlePopupClose = () => {
    this.setState({ isPopupOpen: false, lastActiveIndex: null });
  };

  invokeDelete = (deleteInfo) => {
    this.setState({ deleteInfo });
  };
  handleDeleteVideo = () => {
    const { deleteInfo } = this.state;
    if (deleteInfo?._id) {
      this.props.deleteVideo(deleteInfo._id);
      this.resetDelete()
    }
  };
  resetDelete = () => {
    this.setState({ deleteInfo: null });
  };
  render() {
    const { classes, width } = this.props;
    const { isPopupOpen, activeVideo, lastActiveIndex } = this.state;
    const { name: title, videoLink: url } = activeVideo;
    let variant = "subtitle1";
    if (width === "xl") {
      variant = "h6";
    } else if (width === "lg") {
      variant = "subtitle1";
    } else if (width === "md") {
      variant = "subtitle1";
    } else if (width === "sm") {
      variant = "body2";
    } else if (width === "xs") {
      variant = "body2";
    }
    return (
      <>
        <Player
          title={title}
          url={url}
          isOpen={isPopupOpen}
          handleClickOpen={this.handlePopupClickOpen}
          handleClose={this.handlePopupClose}
        />
        <Grid container spacing={2}>
          {Boolean(this.props?.howToVideos?.videos?.length) ? (
            this.props.howToVideos.videos.map((video, index) => (
              <Grid
                key={video._id}
                container
                item
                xl={4}
                lg={4}
                md={4}
                sm={6}
                xs={12}
                className={classes.videoDemo}
              >
                <Card className={classes.card}>
                  <div className={classes.cardMainBox}>
                    <div className={classes.details}>
                      <CardContent className={classes.content}>
                        <Typography
                          component="h6"
                          variant={variant}
                          align="center"
                          classes={{
                            root: classes.title,
                          }}
                        >
                          {video.name}
                        </Typography>
                      </CardContent>
                      <div className={classes.controls}>
                        <IconButton
                          aria-label="play/pause"
                          onClick={() => this.handleVideoClick(index)}
                        >
                          {lastActiveIndex === index ? (
                            <PauseIcon className={classes.playIcon} />
                          ) : (
                            <PlayArrowIcon className={classes.playIcon} />
                          )}
                        </IconButton>
                        <IconButton
                          aria-label="play/pause"
                          onClick={() => {
                            this.invokeDelete(video);
                          }}
                        >
                          <Delete className={classes.playIcon} />
                        </IconButton>
                      </div>
                    </div>
                    <CardMedia
                      className={classes.cover}
                      image={video.thumbnailImage}
                      title={video.name}
                    />
                  </div>
                </Card>
              </Grid>
            ))
          ) : (
            <div className={classes.noVideosFound}>
              <VideoFile fontSize="large" color="disabled" sx={{ mx: 1 }} />
              <Typography color={"GrayText"}>No Videos Available</Typography>
            </div>
          )}
        </Grid>
        <PopupDialog
          open={Boolean(this.state.deleteInfo)}
          title={
            <>
              Are you sure you want to delete{" "}
              <b>{this.state.deleteInfo?.name || ""}</b> Video?
            </>
          }
          actionTitle="Delete"
          onAction={this.handleDeleteVideo}
          onCancel={this.resetDelete}
          onClose={this.resetDelete}
          actionBg="red"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  howToVideos: state.howToVideos,
});
const mapDispatchToProps = (dispatch) => ({
  deleteVideo: (id) => dispatch(deleteVideo(id)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(VideoGrid)
);
