import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { hideMessage } from "store/snackBarSlice";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MuiAlert from "@mui/material/Alert";

const CustomSnackBar = (props) => {
  const snackbar = useSelector(({ notifications }) => notifications);
  const dispatch = useDispatch();
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(hideMessage());
  };
  return (
    <>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={5000}
        onClose={handleClose}
        message={snackbar.message}
        anchorOrigin={snackbar.position}
        action={
          <>
            <IconButton
              aria-label="close"
              color="inherit"
              sx={{ p: 0.5 }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </>
        }
      >
        {snackbar.type === "success" ? (
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            {snackbar.message}
          </Alert>
        ) : snackbar.type === "error" ? (
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            {snackbar.message}
          </Alert>
        ) : snackbar.type === "warning" ? (
          <Alert
            onClose={handleClose}
            severity="warning"
            sx={{ width: "100%" }}
          >
            {snackbar.message}
          </Alert>
        ) : (
          <></>
        )}
      </Snackbar>
    </>
  );
};

export default CustomSnackBar;
