import {
  Cancel,
  Close,
  PhotoSizeSelectActual,
  VideoCall,
} from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveVideo, uploadThumbnail } from "store/htvSlice";
import { toBase64, toBlob } from "utils";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    "&.MuiDialogTitle-root": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
      margin: "0 0 16px 0",
      display: "flex",
      justifyContent: "space-between",
    },
  },
  title: {
    display: "flex",
    alignItems: "center",
  },
  formContainer: {
    minHeight: 410,
  },
  uploadContainer: {
    color: "#9d9d9d",
    height: 325,
    width: "100%",
    cursor: "default",
    outline: "2px dashed #9d9d9d",
    outlineOffset: -3,
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    // border: "1px solid #9d9d9d",
    // borderRadius: 4,
    height: "100%",
  },
  hoverLayer: {
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.8)",
      color: "white",
    },
    position: "absolute",
    zIndex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100% - 8px)",
    width: "calc(100% - 8px)",
  },
  uploadArea: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    height: "100%",
  },
  removeImgBtn: {
    cursor: "pointer",
    position: "absolute",
    top: 4,
    right: 4,
  },
}));

const initialValues = { title: "", url: "", src: null };

function AddVideo({ open = false, handleClose = () => {} }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const fileRef = useRef(null);
  const [form, setForm] = useState(initialValues);
  const { isUploading } = useSelector(({ howToVideos }) => howToVideos) || {};

  const isValid = Object.values(form).every(Boolean);

  const removeThumbnail = (e) => {
    e.stopPropagation();
    fileRef.current.value = null;
    setForm({ ...form, src: null });
  };

  const handleChange = (event) => {
    const { name = "", value = "", files = [] } = event.target;
    if (!name) return;
    if (name === "src") {
      if (files[0]) {
        setForm({ ...form, [name]: files[0] });
      }
    } else {
      setForm({ ...form, [name]: value });
    }
  };
  function handleCloseDialog() {
    setForm(initialValues);
    handleClose();
  }
  const handleSubmit = () => {
    const { title, src, url } = form;
    const successCB = (srcId) => {
      dispatch(
        saveVideo({
          content: { name: title, videoLink: url, thumbnailImage: srcId },
          successCB: handleCloseDialog,
        })
      );
    };

    const reqBody = {
      file: "",
      imageType: "thumbnail",
      type: src.type,
    };

    toBase64(src).then((value) => {
      reqBody.file = value;
      dispatch(uploadThumbnail({ reqBody, successCB }));
    });
  };

  return (
    <>
      <Dialog onClose={handleCloseDialog} open={open} fullWidth maxWidth="md">
        <DialogTitle className={classes.dialogTitle}>
          <div className={classes.title}>
            <VideoCall fontSize="large" sx={{ mr: 1 }} />
            <Typography variant="h5">Add Video</Typography>
          </div>
          <Close onClick={handleCloseDialog} style={{ cursor: "pointer" }} />
        </DialogTitle>
        <DialogContent className={classes.formContainer}>
          <Grid container spacing={1} sx={{ mt: 1 }}>
            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
              <TextField
                label="Title"
                name="title"
                value={form.title}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
              <TextField
                label="URL"
                name="url"
                value={form.url}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <label htmlFor="how-to-video-upload">
                <input
                  accept="image/*"
                  id="how-to-video-upload"
                  multiple
                  type="file"
                  hidden
                  name="src"
                  onChange={handleChange}
                  ref={fileRef}
                />
              </label>
              <div className={classes.uploadContainer}>
                {!form.src ? (
                  <div
                    className={classes.uploadArea}
                    onClick={() => fileRef.current.click()}
                  >
                    <PhotoSizeSelectActual sx={{ fontSize: 48 }} />
                    Choose Thumbnail to Upload *
                  </div>
                ) : (
                  <div className={classes.imageContainer}>
                    <Cancel
                      onClick={removeThumbnail}
                      className={classes.removeImgBtn}
                    />
                    <img
                      onClick={(e) => e.stopPropagation()}
                      src={toBlob(form.src)}
                      alt="thumbnail"
                      style={{ height: "100%" }}
                    />
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            component="span"
            onClick={handleSubmit}
            disabled={!isValid || isUploading}
          >
            {isUploading ? <CircularProgress size={24} /> : "Submit"}
          </Button>
          <Button
            variant="outlined"
            component="span"
            onClick={handleCloseDialog}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AddVideo;
