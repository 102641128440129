import DashboardConfig from "pages/Dashboard/DashboardConfig";
import LoginConfig from "pages/Login/LoginConfig";
import NoPageConfig from "pages/NoPage/NoPageConfig";
import { Navigate } from "react-router";

const routes = [
  DashboardConfig.route,
  LoginConfig.route,
  NoPageConfig.route,
  {
    path: "/",
    element: <Navigate to="/dashboard" />,
  },
  {
    path: "*",
    element: <Navigate to="/404" replace />,
  },
];

export default routes;
