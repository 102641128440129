import React, { Component } from "react";
import includes from "lodash/includes";
import remove from "lodash/remove";
import isEqual from "lodash/isEqual";
import Typography from "@mui/material/Typography";
import { detectmob } from "utils";
import Tooltip from "components/Tooltip";
import PizzaInner from "./PizzaInner.json";
import PizzaOuter from "./PizzaOuter.json";
import "./Pizza.css";

export default class Pizza extends Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = { enableTooltip: false };
    this.handleClick = this.handleClick.bind(this);
  }
  componentDidUpdate(prevProps) {
    // Update valid group list
    if (!isEqual(this.props.validGroupList, prevProps.validGroupList)) {
      this.props.validGroupList.forEach((group) =>
        this.updateGroupStyle(group, true)
      );
    }
    // Update invalid group list
    if (!isEqual(this.props.invalidGroupList, prevProps.invalidGroupList)) {
      this.props.invalidGroupList.forEach((group) =>
        this.updateGroupStyle(group, false)
      );
    }
  }
  handleClose = () => {
    this.setState({ enableTooltip: false });
  };

  handleClick = (event) => {
    const el = event.target.parentNode;
    const disabled = el.classList.contains("disable-bias") ? true : false;
    if (this.state.enableTooltip) {
      this.setState({ enableTooltip: false });
      return false;
    } else if (disabled) {
      this.setState({ enableTooltip: true });
      return false;
    }
    const selectedGroup = el.getAttribute("data-group");
    let dataBias = el.getAttribute("data-bias");
    dataBias = dataBias ? dataBias.replace("bias-", "") : undefined;

    if (dataBias) {
      let initBias = [];
      switch (dataBias) {
        case "26":
        case "28":
          initBias = ["26", "28"];
          break;
        case "25":
        case "27":
          initBias = ["25", "27"];
          break;
        case "23":
        case "21":
          initBias = ["23", "21"];
          break;
        case "22":
        case "24":
          initBias = ["22", "24"];
          break;
        case "16":
          initBias = ["16", "6"];
          break;
        case "18":
          initBias = ["18", "8"];
          break;
        case "17":
          initBias = ["17", "7"];
          break;
        case "15":
          initBias = ["15", "5"];
          break;
        case "13":
          initBias = ["13", "3"];
          break;
        case "11":
          initBias = ["11", "1"];
          break;
        case "12":
          initBias = ["12", "2"];
          break;
        case "14":
          initBias = ["14", "4"];
          break;
        default:
          initBias = dataBias;
      }
      this.updateBiasArray(initBias, selectedGroup);
    }
  };

  updateGroupStyle = (group, enable) => {
    const all = document.querySelectorAll(`[data-group=${group}]`);
    for (let i = 0; i < all.length; i++) {
      if (enable) {
        all[i].classList.remove("disable-bias");
      } else {
        all[i].classList.add("disable-bias");
      }
    }
  };

  updateBiasArray = (selectedBias, selectedGroup) => {
    let { bias, updateBias } = this.props;
    let biasVal = [...bias];
    if (includes(biasVal, selectedBias[0])) {
      remove(biasVal, function (n) {
        return includes(selectedBias, n);
      });
      updateBias(biasVal, selectedGroup);
    } else {
      biasVal = biasVal.concat(selectedBias);
      updateBias(biasVal, selectedGroup);
    }
  };

  renderPizzaGroup(group) {
    const fill = "#E6E6E6";
    const prefix = "bias";
    const { bias } = this.props;
    return group.map((item) => {
      const { id, d, biasGroup } = item;
      return (
        <g key={id}>
          <g
            data-group={biasGroup}
            data-bias={`${prefix}-${id}`}
            className={`${biasGroup}`}
          >
            <path
              fill={fill}
              d={d}
              className={includes(bias, "" + id) ? "active" : undefined}
            />
          </g>
        </g>
      );
    });
  }

  render() {
    const isMobe = detectmob();
    const tooltip = {
      position: "relative",
    };
    const tooltiptextItem = {
      padding: "5px 10px",
    };
    return (
      <div style={tooltip}>
        {this.state.enableTooltip && (
          <Tooltip handleClose={this.handleClose}>
            <Typography
              variant="subtitle1"
              color="secondary"
              style={tooltiptextItem}
            >
              {`${
                this.props.stage.length === 1 ? "Current selection:" : ""
              } Stage 1 - (simple unidirectional exercises) select within one
              quadrant only`}
            </Typography>
            <Typography
              variant="subtitle1"
              color="secondary"
              style={tooltiptextItem}
            >
              {`${
                this.props.stage.length === 2 ? "Current selection:" : ""
              } Stage 2 - (complex unidirectional exercises) select
              within one hemisphere only`}
            </Typography>
            <Typography
              variant="subtitle1"
              color="secondary"
              style={tooltiptextItem}
            >
              {`${
                this.props.stage.length === 3 ? "Current selection:" : ""
              } Stage 3 - (multidirectional exercises)
              select any combination`}
            </Typography>
          </Tooltip>
        )}
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 1032.833 1051.194"
          enableBackground="new 0 0 1032.833 1051.194"
          onClick={this.handleClick}
          className={!isMobe ? "fill-me" : undefined}
        >
          {this.renderPizzaGroup(PizzaInner)}
          <g>
            <g>
              <line
                fill="none"
                stroke="#E6E6E6"
                x1="511.311"
                y1="126.059"
                x2="511.311"
                y2="923.583"
              />
            </g>
          </g>
          <g>
            <g>
              <line
                fill="none"
                stroke="#E6E6E6"
                x1="110.167"
                y1="525.691"
                x2="911.356"
                y2="525.691"
              />
            </g>
          </g>
          <g>
            <g>
              <g className="letter">
                <path
                  fill="#E6E6E6"
                  d="M523.048,102.703h-24.203v24.274h-4.384V75.078h32.151v3.707h-27.767v20.21h24.203V102.703z"
                />
              </g>
            </g>
          </g>
          <g className="letter">
            <path
              fill="#E6E6E6"
              d="M523.619,950.743h-24.274v21.172h27.91v3.707h-32.294v-51.898h32.116v3.707h-27.731v19.605h24.274V950.743z"
            />
          </g>
          <g>
            <g>
              <g className="letter">
                <path
                  fill="#E6E6E6"
                  d="M932.685,527.374h-15.078v21.636h-4.42v-51.898h16.86c5.537,0,9.861,1.341,12.975,4.023 c3.113,2.682,4.669,6.432,4.669,11.25c0,3.323-0.975,6.254-2.923,8.794c-1.949,2.54-4.563,4.296-7.842,5.269l12.975,22.098v0.464 h-4.669L932.685,527.374z M917.607,523.667h13.438c3.683,0,6.642-1.047,8.875-3.142c2.233-2.094,3.351-4.808,3.351-8.14 c0-3.665-1.165-6.509-3.493-8.532c-2.329-2.022-5.596-3.035-9.802-3.035h-12.369V523.667z"
                />
              </g>
            </g>
          </g>
          <g>
            <g>
              <g className="letter">
                <path
                  fill="#E6E6E6"
                  d="M84.547,545.303h25.628v3.707H80.127v-51.898h4.42V545.303z"
                />
              </g>
            </g>
          </g>
          {this.renderPizzaGroup(PizzaOuter)}
        </svg>
      </div>
    );
  }
}
