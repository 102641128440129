import React from "react";
// import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import ReactPlayer from "react-player";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  dialogPaper: {
    height: "100% !important",
  },
  playerSize: {
    width: "100% !important",
    height: "100% !important",
  },
  "@media (max-width: 1024px)": {
    dialogPaper: {
      height: "80%",
    },
    playerSize: {
      height: "60vh !important",
      // webkitAppearance: '60vh !important'
    },
  },
  "@media (max-width: 991px)": {
    playerSize: {
      height: "70vh !important",
    },
  },
  "@media (max-width: 767px)": {
    dialogPaper: {
      height: "100%",
    },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AlertDialogSlide(props) {
  const { classes, isOpen, title, url, handleClose } = props;

  return (
    <div>
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth={"xl"}
        fullWidth
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
        <DialogContent>
          <ReactPlayer
            url={url}
            playing={isOpen}
            controls
            config={{
              youtube: {
                playerVars: { showinfo: 1 },
                iframeParams: { fullscreen: 1 },
              },
            }}
            // width="100%"
            // height="100%"
            className={classes.playerSize}
            // Other ReactPlayer props will work here
          />
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button onClick={handleClose} color="primary">
            Agree
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}

export default withStyles(styles)(AlertDialogSlide);
