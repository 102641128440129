import React from "react";
import { Button } from "@mui/material";
import { withStyles } from "@mui/styles";
import { ReactComponent as BoundaryLogo } from "../../img/error-boundary.svg";

const styles = (theme) => ({
  errorContainer: {
    textAlign: "center",
    position: "absolute",
    top: "10%",
    width: "100%",
    color: theme.palette.primary.main,
  },
  headText: {
    fontSize: 92,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  subText: {
    fontSize: 24,
  },
  refreshBtn: {
    border: "1px solid rgba(11, 158, 164, 0.5)",
  },
});

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    const { classes } = this.props;
    const { error, errorInfo } = this.state;
    if (this.state.errorInfo) {
      // Error path
      console.log(`error`, error);
      console.log(`errorInfo`, errorInfo);
      return (
        <div className={classes.errorContainer}>
          <BoundaryLogo width={"30%"} height={"30%"} />
          <h1 className={classes.headText}>Oops!</h1>
          <br />
          <h1 className={classes.subText}>Something went wrong...</h1>
          <br />
          <p style={{ fontSize: 18 }}>
            We appreciate your patience while we examine the system. Please try
            again later.
          </p>
          <div className={classes.subText}>
            <br />
            <Button
              onClick={() => {
                window.location.replace("/");
              }}
              className={classes.refreshBtn}
              variant="contained"
              color="primary"
            >
              Go to Homepage
            </Button>
          </div>
          {/* {error && (
            <details style={{ whiteSpace: "pre-wrap" }}>
              {error && error.toString()}
              <br />
              {errorInfo && errorInfo.componentStack}
            </details>
          )} */}
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default withStyles(styles)(ErrorBoundary);
