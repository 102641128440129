import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  message: "Something went wrong.",
  open: false,
  type: "",
  position: { vertical: "top", horizontal: "center" },
};

export const SnackBarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    showMessage: (state, action) => {
      state.open = true;
      state.message = action.payload.message || initialState.message;
      state.type = action.payload.type || initialState.type;
      state.position = action.payload.position || initialState.position;
    },
    hideMessage: (state) => {
      state.open = false;
      state.message = "";
    },
  },
});

export const { showMessage, hideMessage } = SnackBarSlice.actions;

export default SnackBarSlice.reducer;
