import Clinical from "images/clinical-pilates-3.png";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  loginContainer: {
    background: "#fff",
  },
  formContainer: {
    height: "100vh",
  },
  container: {
    padding: "8px 30px 25px",
  },
  loginForm: {
    width: "50%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  signup: {
    height: "auto",
    marginBottom: 0,
    padding: "25px 0",
  },
  brandLogoContainer: { textAlign: "center" },
  brandLogo: {
    height: "120px",
    width: "150px",
    position: "relative",
    marginBottom: "40px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      width: 90,
      height: 60,
    },
  },
  formTitle: {
    textAlign: "center",
    margin: 0,
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
      marginBottom: 10,
    },
  },
  form: {
    width: "100%",
    textAlign: "center",
    marginTop: "40px",
  },
  textField: {
    position: "relative",
    width: "100%",
    backgroundColor: "#f7f7f7",
    marginBottom: "20px",
    borderRadius: "inherit",
    [theme.breakpoints.down("xs")]: {
      width: 290,
    },
  },
  registerText: {
    color: "#b7b7b7",
  },
  registerBtn: {
    color: "#7bbed0",
    padding: "0px",
    fontSize: "14px",
    fontWeight: "normal",
    textTransform: "capitalize",
  },
  forgotText: {
    marginBottom: "10px",
  },
  forgotPasswordMain: {
    justifyContent: "flex-end",
    '& a' : {
      textDecoration: "none"
    }
  },
  loginBtn: {
    marginBottom: 20,
    letterSpacing: 1,
    minHeight: 42
  },
  noAccount: {
    marginRight: "0.5rem",
  },
  clinicalImage: {
    width: "100%",
    backgroundImage: `url(${Clinical})`,
    backgroundPosition: "50%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "100%",
    position: "relative",
    "&::after": {
      content: `' '`,
      backgroundColor: "#6aafc8",
      position: "absolute",
      height: "100%",
      width: "100%",
      opacity: "0.4",
      left: 0
    }
  },
  card: {
    boxShadow:
      "0px 1px 25px 0px rgba(0, 0, 0, 0.1), 0px 1px 40px 0px rgba(0, 0, 0, 0), 0px 2px 1px -1px rgba(0, 0, 0, 0)",
  },
  buttonWrapper: {
    // margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -22,
    marginLeft: -12,
  },

  checkBox: {
    padding: 0,
  },
  terms: {
    marginBottom: "20px",
  },
  link: {
    color: "#0b9ea4",
    cursor: "pointer",
  },
  listItem: {
    paddingInlineStart: "40px",
    marginBlockStart: "1em",
    marginBlockEnd: "1em",
    color: "#717171",
    fontSize: "0.85rem",
  },

  "@media (max-width: 1024px)": {
    signup: {
      margin: 0,
    },
  },
  "@media (max-width: 991px)": {
    signup: {
      height: "100vh",
      margin: 0,
    },
    brandLogo: {
      marginBottom: 20,
    },
  },
  "@media (max-width: 823px)": {
    signup: {
      height: "auto",
      padding: "25px 0",
    },
    loginForm: {
      height: "auto",
      padding: "25px 0",
    },
  },
  "@media (max-width: 767px)": {
    signup: {
      padding: "25px 0",
      margin: 0,
      height: "auto",
      display: "flex",
      flexDirection: "row",
    },
    loginForm: {
      padding: "25px 0",
      height: "auto",
      display: "flex",
      flexDirection: "row",
    },
    brandLogo: {
      marginBottom: 15,
    },
    cardContent: {
      padding: 0,
    },
    formTitle: {
      padding: "10px 0",
    },
  },
  "@media (max-width: 567px)": {
    container: {
      padding: 0,
      margin: 0,
    },
    card: {
      padding: 20,
    },
    formTitle: {
      padding: 0,
    },
  },
  "@media (max-width: 320px)": {
    container: {
      padding: "8px 0px 20px",
    },
    loginForm: {
      display: "block",
    },
    brandLogo: {
      margin: "20px 0 0 !important",
    },
    card: {
      boxShadow: "none !important",
      padding: 0,
    },
    brandLogoContainer: {
      marginBottom: 15,
    },
  },
  "@media screen and  (-webkit-min-device-pixel-ratio:0)": {
    formContainer: {
      height: "100%",
    },
  },

  //New style
  loginLeftContent: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    height: "100vh"
  },
  loginFormCard: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    maxWidth: 425,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      padding: "0 12px",
      margin: "0 16px"
    }
  },
}));

export default useStyles;
