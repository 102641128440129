import React from "react";
import { Grid } from "@mui/material";
import HowToVideo from "./HowToVideo";
import BiasClassification from "./BiasClassification";

const Dashboard = (props) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <HowToVideo />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <BiasClassification />
      </Grid>
    </Grid>
  );
};

export default Dashboard;
