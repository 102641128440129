import PropTypes from "prop-types";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  Switch,
  IconButton,
  Tooltip,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import _, { includes } from "lodash";
import React from "react";
import { connect } from "react-redux";
import {
  deleteExercise,
  getExercises,
  getTemplates,
} from "store/exercisesSlice";
import ProgramExercise from "../Assessment/Exercise/ProgramExercise";
import TreatmentExercise from "../Assessment/Exercise/Treatment";
import clsx from "clsx";
import { AccessibilityNew, AddCircle, PieChart } from "@mui/icons-material";
import commonStyles from "styles/CommonStyle";
import { combineStyles } from "utils";
import BiasSection from "./BiasSection";
import AddExercise from "./AddExercise";
import PopupDialog from "components/PopupDialog/PopupDialog";
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

const styles = (theme) => ({
  commonAccordionMain: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  mainContainer: {
    margin: "20px 0",
  },
  headerText: {
    "&.MuiTypography-root": {
      fontSize: 20,
    },
  },
  panelSummary: {
    justifyContent: "space-between",
    alignItems: "center",
    "&.MuiAccordionSummary-root.Mui-expanded": {
      minHeight: 48,
    },
    "&.MuiAccordionSummary-content.Mui-expanded": {
      margin: "12px 0",
    },
  },
  "@media (max-width: 767px)": {
    headerText: {
      fontSize: 16,
    },
  },
  commonBoxLayout: {
    padding: 15,
    background: "linear-gradient(to right bottom, #299dca, #29b09b)",
    boxShadow:
      "0 4px 20px 0px rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(233 30 99 / 40%)",
    marginTop: "-40px",
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 3,
    display: "flex",
  },
  commonIconBox: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    "&.MuiButtonBase-root": { fontSize: "0.675rem", lineHeight: "normal" },
  },
  stage: { display: "flex", fontSize: "0.6rem", justifyContent: "center" },
});

function TabPanel(props) {
  const { children, value, index, name, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`${name}-tabpanel-${index}`}
      aria-labelledby={`${name}-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box bgcolor="white" sx={{ mt: 2, mb: 2 }} div={12}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  name: PropTypes.any.isRequired,
};

function a11yProps(name, index) {
  return {
    id: `${name}-tab-${index}`,
    'aria-controls': `${name}-tabpanel-${index}`,
  };
}

const initialState = {
  exerciseCategories: {
    MW: [],
    REF: [],
    TT: [],
    TB: [],
    ACC: [],
  },
  selectedBias: [],
  selectedCategory: [],
  selectedUnits: [],
  selectedExercises: [],
  treatmentMode: false,
  openAddDialog: false,
  stage: [],
  bias: [],
  stagesBias: [],
  unit: [],
  selectedTemplate: "",
  imageMode: true,
  exerciseInfo: null,
  ExercisesTabValue:0,
};

const initStagesBias = {
  stage1: {
    group: "",
    selectedBias: [],
  },
  stage2: {
    group: "",
    selectedBias: [],
  },
  stage3: {
    group: [],
    selectedBias: [],
  },
};

class BiasClassification extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
  }

  componentDidMount() {
    this.props.getTemplates();
    this.props.getExercises();
  }

  componentDidUpdate(prevProps, prevState) {
    const { exercises: prevExercises } = prevProps.exercises;
    const { exercises } = this.props.exercises;
    if (!_.isEqual(prevExercises, exercises)) {
      this.getExercisesOnBias();
    }
  }

  handleOpenDialog = () => {
    this.setState({ openAddDialog: true });
  };
  handleCloseDialog = () => {
    this.setState({ openAddDialog: false, exerciseInfo: null });
  };

  handleEditExercise = (exerciseInfo) => {
    this.setState({ exerciseInfo });
    this.handleOpenDialog();
  };

  inBias = (item) => {
    const { bias } = this.state;
    return bias.includes("" + item);
  };

  getExercisesOnBias = () => {
    const { exercises } = this.props.exercises;
    const { selectedCategory, stage, bias, unit } = this.state;
    if (bias.length) {
      const exerciseCategories = {
        MW: [],
        REF: [],
        TT: [],
        TB: [],
        ACC: [],
      };
      const selectableExercises = [];
      exercises.forEach((exercise) => {
        const validStage = stage.includes(exercise.stage);
        const validUnit = unit.includes(exercise.unit);
        const validBias = exercise.bias.some(this.inBias);
        if (validStage && validUnit && validBias) {
          selectableExercises.push(exercise);
        }
      });
      selectableExercises.forEach((exercise) => {
        exercise.category.forEach((cat) => {
          if (exerciseCategories[cat]) {
            exerciseCategories[cat].push(exercise);
          }
        });
      });

      // alphabetically sorting by exercise name
      if (selectedCategory.length) {
        selectableExercises.forEach((exercise) => {
          exercise.category.forEach((cat) => {
            if (exerciseCategories[cat]) {
              exerciseCategories[cat].sort((a, b) => {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
                return 0;
              });
            }
          });
        });
      }

      this.setState({ exerciseCategories });
    } else {
      const exerciseCategories = {
        MW: [],
        REF: [],
        TT: [],
        TB: [],
        ACC: [],
      };
      this.setState({ exerciseCategories });
    }
  };

  autofillStagesBias = (selectedBias, callback) => {
    let stage = [1];
    const FLBias = ["12", "2", "22", "24", "14", "4"];
    const FRBias = ["6", "16", "8", "18", "26", "28"];
    const ELBias = ["11", "1", "13", "3", "23", "21"];
    const ERBias = ["5", "15", "7", "17", "25", "27"];

    let stage1 = {
      group: "",
      selectedBias: [],
    };
    let stage2 = {
      group: "",
      selectedBias: [],
    };
    let stage3 = {
      group: [],
      selectedBias: [],
    };

    // Init Stages Bias
    const mutualFLBias = FLBias.filter((bias) => selectedBias.includes(bias));
    const mutualFRBias = FRBias.filter((bias) => selectedBias.includes(bias));
    const mutualELBias = ELBias.filter((bias) => selectedBias.includes(bias));
    const mutualERBias = ERBias.filter((bias) => selectedBias.includes(bias));

    if (mutualFLBias.length) {
      stage1 = {
        group: "FL",
        selectedBias: mutualFLBias,
      };
      if (mutualFRBias.length) {
        stage2 = {
          group: "FR",
          selectedBias: mutualFRBias,
        };
      }
      if (mutualELBias.length || mutualERBias.length) {
        stage3 = {
          group: ["EL", "ER"],
          selectedBias: [...mutualELBias, ...mutualERBias],
        };
      }
    } else if (mutualFRBias.length) {
      stage1 = {
        group: "FR",
        selectedBias: mutualFRBias,
      };
      if (mutualELBias.length || mutualERBias.length) {
        stage3 = {
          group: ["EL", "ER"],
          selectedBias: [...mutualELBias, ...mutualERBias],
        };
      }
    } else if (mutualELBias.length) {
      stage1 = {
        group: "EL",
        selectedBias: mutualELBias,
      };
      if (mutualERBias.length) {
        stage2 = {
          group: "ER",
          selectedBias: mutualERBias,
        };
      }
    } else if (mutualERBias.length) {
      stage1 = {
        group: "ER",
        selectedBias: mutualERBias,
      };
    }
    const stagesBias = {
      stage1,
      stage2,
      stage3,
    };

    // Init Stage
    if (
      stage1.selectedBias.length &&
      stage2.selectedBias.length &&
      stage3.selectedBias.length
    ) {
      stage = [1, 2, 3];
    } else if (stage1.selectedBias.length && stage3.selectedBias.length) {
      stage = [1, 2, 3];
    } else if (stage1.selectedBias.length && stage2.selectedBias.length) {
      stage = [1, 2];
    } else {
      stage = [1];
    }

    this.setState({ stage, stagesBias }, callback);
  };

  setTemplate = (template) => {
    const { title, detail } = template;
    let {
      // selectedExercises,
      selectedBias: bias,
      selectedCategory,
      selectedUnits: unit,
    } = detail;
    let selectedExercises = [];

    selectedExercises = this.props.exercises.exercises.filter((ex) =>
      bias.some((c) => ex.bias.includes(Number(c)))
    );

    const initTemplate = () => {
      if (this.state.selectedTemplate === title) {
        this.setState(
          {
            anchorEl: null,
            bias: initialState.bias,
            selectedCategory: initialState.selectedCategory,
            selectedExercises: initialState.selectedExercises,
            unit,
            // treatmentMode: title !== "Generic",
            selectedBias: initialState.selectedBias,
            selectedUnits: initialState.unit,
            selectedTemplate: "",
          },
          () => {
            this.getExercisesOnBias();
          }
        );
      } else {
        this.setState(
          {
            anchorEl: null,
            bias,
            selectedCategory,
            selectedExercises,
            unit,
            // treatmentMode: title !== "Generic",
            selectedBias: bias,
            selectedUnits: unit,
            selectedTemplate: title,
          },
          () => {
            this.getExercisesOnBias();
          }
        );
      }
    };

    this.autofillStagesBias(bias, initTemplate);
  };

  handleCategoryChange = (category) => {
    if (category) {
      const { selectedCategory } = this.state;
      const categories = [...selectedCategory];
      if (categories.includes(category)) {
        const index = categories.indexOf(category);
        categories.splice(index, 1);
      } else {
        categories.unshift(category);
      }
      this.setState({ selectedCategory: categories }, () =>
        this.getExercisesOnBias()
      );
    }
  };

  updateBias = (bias, group) => {
    let { stage, stagesBias } = this.state;
    if (!bias.length) {
      // Reset pizza on empty selection
      stagesBias = { ...initStagesBias };
    } else if (stage.length === 1) {
      // handle stage1
      stagesBias = { ...stagesBias, stage1: { group, selectedBias: bias } };
    } else if (stage.length === 2) {
      // handle stage2
      if (!stagesBias.stage1.group) {
        // Init first quadrants to stage1
        stagesBias = {
          ...stagesBias,
          stage1: { group, selectedBias: bias },
        };
      } else if (stagesBias.stage1.group === group) {
        // add bias to stage1
        if (!stagesBias.stage2.group) {
          stagesBias = {
            ...stagesBias,
            stage1: { group, selectedBias: bias },
          };
        } else {
          const stage1Bias = bias.filter(
            (item) => !stagesBias.stage2.selectedBias.includes(item)
          );
          stagesBias = {
            ...stagesBias,
            stage1: { group, selectedBias: stage1Bias },
          };
        }
      } else if (
        stagesBias.stage1.group &&
        stagesBias.stage1.group !== group &&
        !stagesBias.stage2.group
      ) {
        // Init secound quadrants for stage2
        let nextStage2Group = "";
        switch (stagesBias.stage1.group) {
          case "ER":
            nextStage2Group = "EL";
            break;
          case "EL":
            nextStage2Group = "ER";
            break;
          case "FL":
            nextStage2Group = "FR";
            break;
          case "FR":
            nextStage2Group = "FL";
            break;
          default:
            nextStage2Group = "";
        }

        if (group === nextStage2Group) {
          // add bias to stage2
          const stage2Bias = bias.filter(
            (item) => !stagesBias.stage1.selectedBias.includes(item)
          );
          stagesBias = {
            ...stagesBias,
            stage2: { group, selectedBias: stage2Bias },
          };
        }
      } else if (stagesBias.stage2.group === group) {
        // add bias to stage2
        const stage2Bias = bias.filter(
          (item) => !stagesBias.stage1.selectedBias.includes(item)
        );
        stagesBias = {
          ...stagesBias,
          stage2: { group, selectedBias: stage2Bias },
        };
      }
    } else if (stage.length === 3) {
      // handle stage3
      if (!stagesBias.stage1.group) {
        // Init first quadrants to stage1
        stagesBias = {
          ...stagesBias,
          stage1: { group, selectedBias: bias },
        };
      } else if (stagesBias.stage1.group === group) {
        // add bias to stage1
        let stage1Bias = [...bias];

        // clean state2 bias
        if (stagesBias.stage2.selectedBias.length) {
          stage1Bias = stage1Bias.filter(
            (item) => !stagesBias.stage2.selectedBias.includes(item)
          );
        }
        // clean state3 bias
        if (stagesBias.stage3.selectedBias.length) {
          stage1Bias = stage1Bias.filter(
            (item) => !stagesBias.stage3.selectedBias.includes(item)
          );
        }

        stagesBias = {
          ...stagesBias,
          stage1: { group, selectedBias: stage1Bias },
        };
      } else if (
        stagesBias.stage1.group &&
        stagesBias.stage1.group !== group &&
        !stagesBias.stage2.group
      ) {
        // Init secound quadrants for stage2/hemisphere for stage 3
        let nextStage2Group = "";
        switch (stagesBias.stage1.group) {
          case "ER":
            nextStage2Group = "EL";
            break;
          case "EL":
            nextStage2Group = "ER";
            break;
          case "FL":
            nextStage2Group = "FR";
            break;
          case "FR":
            nextStage2Group = "FL";
            break;
          default:
            nextStage2Group = "";
        }

        if (group === nextStage2Group) {
          // Init quadrants to stage2
          let stage2Bias = bias.filter(
            (item) => !stagesBias.stage1.selectedBias.includes(item)
          );

          // clean stage3 bias
          if (stagesBias.stage3.selectedBias.length) {
            stage2Bias = stage2Bias.filter(
              (item) => !stagesBias.stage3.selectedBias.includes(item)
            );
          }

          stagesBias = {
            ...stagesBias,
            stage2: { group, selectedBias: stage2Bias },
          };
        } else {
          let stage3Bias = bias.filter(
            (item) => !stagesBias.stage1.selectedBias.includes(item)
          );
          const groupUpdate = [...stagesBias.stage3.group];
          if (!groupUpdate.includes(group)) {
            groupUpdate.push(group);
          }
          // Init hemisphere for stage 3
          stagesBias = {
            ...stagesBias,
            stage3: { group: groupUpdate, selectedBias: stage3Bias },
          };
        }
      } else if (stagesBias.stage2.group === group) {
        // add bias to stage2
        let stage2Bias = bias.filter(
          (item) => !stagesBias.stage1.selectedBias.includes(item)
        );
        if (stagesBias.stage3.selectedBias.length) {
          stage2Bias = stage2Bias.filter(
            (item) => !stagesBias.stage3.selectedBias.includes(item)
          );
        }
        stagesBias = {
          ...stagesBias,
          stage2: { group, selectedBias: stage2Bias },
        };
      } else {
        // add bias to stage3

        if (!stagesBias.stage3.group.includes(group)) {
          // include group
          let groupUpdate = [...stagesBias.stage3.group];
          groupUpdate.push(group);
        }

        // clean stage1 bias
        let stag3Bias = bias.filter(
          (item) => !stagesBias.stage1.selectedBias.includes(item)
        );

        // clean stage2 bias
        if (stagesBias.stage2.selectedBias.length) {
          stag3Bias = stag3Bias.filter(
            (item) => !stagesBias.stage2.selectedBias.includes(item)
          );
        }
        stagesBias = {
          ...stagesBias,
          stage3: { group, selectedBias: stag3Bias },
        };
      }
    }
    this.setState({ bias, stagesBias }, () => {
      this.getExercisesOnBias();
    });
  };

  handleStageClick = (selectedStage) => {
    let { bias, stage, stagesBias } = this.state;
    // if (includes(stage, +selectedStage)) {
    //   const index = stage.indexOf(selectedStage);
    //   stage.splice(index, 1);
    // } else {
    //   stage.push(+selectedStage);
    // }
    if (selectedStage === "1") {
      if (stage.includes(2)) {
        bias = [...stagesBias.stage1.selectedBias];
        stagesBias = {
          ...stagesBias,
          stage2: {
            group: "",
            selectedBias: [],
          },
          stage3: {
            group: "",
            selectedBias: [],
          },
        };
      }
      stage = [1];
    } else if (selectedStage === "2") {
      if (stage.includes(2)) {
        bias = [...stagesBias.stage1.selectedBias];
        stagesBias = {
          ...stagesBias,
          stage2: {
            group: "",
            selectedBias: [],
          },
          stage3: {
            group: "",
            selectedBias: [],
          },
        };
        stage = [1];
      } else {
        stage = [1, 2];
      }
    } else if (selectedStage === "3") {
      if (stage.includes(3)) {
        bias = [
          ...stagesBias.stage1.selectedBias,
          ...stagesBias.stage2.selectedBias,
        ];
        stagesBias = {
          ...stagesBias,
          stage3: {
            group: "",
            selectedBias: [],
          },
        };
        stage = [1, 2];
      } else {
        stage = [1, 2, 3];
      }
    }
    this.setState({ bias, stage, stagesBias }, () => this.getExercisesOnBias());
  };

  handleUnitClick = (selectedUnit) => {
    const { unit } = this.state;
    const units = [...unit];
    if (units.includes(selectedUnit)) {
      const index = units.indexOf(selectedUnit);
      units.splice(index, 1);
    } else {
      units.push(selectedUnit);
    }
    this.setState({ unit: units }, () => this.getExercisesOnBias());
  };

  handleSwitchChange = (event) => {
    const { name } = event.target;
    this.setState((state) => ({ [name]: !state[name] }));
  };

  invokeDeletePopup = (deleteInfo) => {
    this.setState({ deleteInfo });
  };

  resetDelete = () => {
    this.setState({ deleteInfo: null });
  };

  handleDeleteExercise = () => {
    const { deleteInfo } = this.state;
    if (deleteInfo?._id) {
      this.props.deleteExercise({
        id: deleteInfo._id,
        successCB: this.resetDelete,
      });
    }
  };
  renderProgramforTab = (bias, selectedCategory, stage, unit) => {
    const { exercises } = this.props.exercises;      
      const { classes, appointment } = this.props;
    const {
      treatmentMode,
      imageMode,
    } = this.state;
    const exerciseCategories = {
        MW: [],
        REF: [],
        TT: [],
        TB: [],
        ACC: [],
      };     
      const selectableExercises = [];
      
    if (bias.length) {
      exercises.forEach((exercise) => {
        const validStage = stage.includes(exercise.stage);
        const validUnit = unit.includes(exercise.unit);
        
        const validBias = exercise.bias.some(function(item){
          return bias.includes(item);
        });
        if (validStage && validUnit && validBias) {
          selectableExercises.push(exercise);
        }
      });
      selectableExercises.forEach((exercise) => {
        exercise.category.forEach((cat) => {
          if (exerciseCategories[cat]) {
            exerciseCategories[cat].push(exercise);
          }
        });
      });
    }
   const activeExercises = selectableExercises.map((exercise) => {
      return exercise._id;
    });
    
    return (
      
      <Grid
        className={clsx({
          [classes.planSectionFullWidth]: bias,
          [classes.planSection]: !bias,
        })}
      >
        <Grid className={classes.personalDetailsInner}>
          <Grid
            className={[
              classes.personalDetailsHeader,
              classes.planSectionHeight,
            ].join(" ")}
          >
            <Grid
              container
              justify="space-between"
              className={classes.switchGroup}
            >
              <Grid className={classes.switchBox}>
                <Typography
                  className={[classes.switchTitle, classes.detailsTitle].join(
                    " "
                  )}
                >
                  Name
                </Typography>
                <Switch
                  value={imageMode}
                  name="imageMode"
                  checked={imageMode}
                  onChange={this.handleSwitchChange}
                  color="primary"
                />
                <Typography
                  className={[classes.switchTitle, classes.detailsTitle].join(
                    " "
                  )}
                >
                  Image
                </Typography>
              </Grid>
            </Grid>
            {treatmentMode ? (
              <TreatmentExercise
                imageMode={imageMode}
                onUpdate={this.handleExercisesUpdate}
                onRemove={this.handleExercisesRemove}
                selectedExercises={selectableExercises}
                autoSize={true}
              />
            ) : (
              <ProgramExercise
                exerciseCategories={exerciseCategories}
                selectedCategory={selectedCategory}
                handleClick={this.handleExerciseClick}
                imageMode={imageMode}
                activeList={activeExercises}
                selectedExercises={selectableExercises}
                onRemoveItem={this.handleExerciseClick}
                bias={bias}
                handleEditExercise={this.handleEditExercise}
                invokeDeletePopup={this.invokeDeletePopup}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    );

  };



  renderProgram = () => {
    const { classes, appointment } = this.props;
    const {
      exerciseCategories,
      selectedCategory,
      selectedExercises,
      treatmentMode,
      imageMode,
    } = this.state;
    const activeExercises = selectedExercises.map((exercise) => {
      return exercise._id;
    });
    const bias = includes(appointment, "bias");
    return (
      
      <Grid
        className={clsx({
          [classes.planSectionFullWidth]: bias,
          [classes.planSection]: !bias,
        })}
      >
        <Grid className={classes.personalDetailsInner}>
          <Grid
            className={[
              classes.personalDetailsHeader,
              classes.planSectionHeight,
            ].join(" ")}
          >
            <Grid
              container
              justify="space-between"
              className={classes.switchGroup}
            >
              <Grid className={classes.switchBox}>
                <Typography
                  className={[classes.switchTitle, classes.detailsTitle].join(
                    " "
                  )}
                >
                  Name
                </Typography>
                <Switch
                  value={imageMode}
                  name="imageMode"
                  checked={imageMode}
                  onChange={this.handleSwitchChange}
                  color="primary"
                />
                <Typography
                  className={[classes.switchTitle, classes.detailsTitle].join(
                    " "
                  )}
                >
                  Image
                </Typography>
              </Grid>
            </Grid>
            {treatmentMode ? (
              <TreatmentExercise
                imageMode={imageMode}
                onUpdate={this.handleExercisesUpdate}
                onRemove={this.handleExercisesRemove}
                selectedExercises={selectedExercises}
                autoSize={true}
              />
            ) : (
              <ProgramExercise
                exerciseCategories={exerciseCategories}
                selectedCategory={selectedCategory}
                handleClick={this.handleExerciseClick}
                imageMode={imageMode}
                activeList={activeExercises}
                selectedExercises={selectedExercises}
                onRemoveItem={this.handleExerciseClick}
                bias={bias}
                handleEditExercise={this.handleEditExercise}
                invokeDeletePopup={this.invokeDeletePopup}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  };
  ExercisesTabsHandleChange = (event, newValue) => {
    this.setState({ ExercisesTabValue: newValue })

  };
  
  render() {
    const { props } = this;
    const { classes, exercises: exerciseRed } = props;
    const { templates } = exerciseRed;

    let {
      stage = [],
      bias = [],
      stagesBias,
      selectedTemplate,
      selectedCategory,
      unit,
      exerciseInfo,
      deleteInfo,
    } = this.state;

    let validGroupList = [];
    let invalidGroupList = [],
      groupList = [];
    if (bias.length && stage.length === 1) {
      const selectedGroup = stagesBias.stage1.group;
      groupList.forEach((group) => {
        if (group !== selectedGroup) {
          invalidGroupList.push(group);
        } else {
          validGroupList.push(group);
        }
      });
    } else if (bias.length && stage.length === 2) {
      const stageOneGroup = stagesBias.stage1.group;
      if (stageOneGroup === "FR" || stageOneGroup === "FL") {
        validGroupList = ["FR", "FL"];
        invalidGroupList = ["ER", "EL"];
      } else {
        validGroupList = ["ER", "EL"];
        invalidGroupList = ["FR", "FL"];
      }
    } else {
      validGroupList = ["FR", "ER", "EL", "FL"];
      invalidGroupList = [];
    }

    return (
      <>
        <Grid container >
         
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                classes={{ expanded: classes.panelSummary }}
              >
                <div className={classes.commonAccordionMain}>
                  <div className={classes.commonIconBox}>
                    <div className={classes.commonBoxLayout}>
                      <AccessibilityNew color="secondary" />
                    </div>
                    <Typography
                      variant="body2"
                      color="primary"
                      className={classes.headerText}
                    >
                      Exercises
                    </Typography>
                  </div>
                  <div>
                    <IconButton
                      aria-label="Add Video"
                      style={{ height: 28, width: 28, padding: 4 }}
                      onClick={(event) => {
                        this.handleOpenDialog();
                        event.stopPropagation();
                      }}
                    >
                      <Tooltip
                        title="Add New Exercise"
                        aria-label="Add New Exercise"
                      >
                        <AddCircle color="primary" fontSize="large" />
                      </Tooltip>
                    </IconButton>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>

                <Box sx={{ width: '100%' }} div={12}>
                  <AppBar elevation={0} color="secondary" position="static">
                    <Tabs selectionFollowsFocus indicatorColor="primary" value={this.state.ExercisesTabValue} onChange={this.ExercisesTabsHandleChange} aria-label="ExercisesTab tabs">
                      <Tab label="Left Flexion" className={classes.ExercisesTabNavButton} {...a11yProps('ExercisesTab', 0)} />
                      <Tab label="Left Extension" className={classes.ExercisesTabNavButton} {...a11yProps('ExercisesTab', 1)} />
                      <Tab label="Right Flexion" className={classes.ExercisesTabNavButton} {...a11yProps('ExercisesTab', 2)} />
                      <Tab label="Right Extension" className={classes.ExercisesTabNavButton} {...a11yProps('ExercisesTab', 3)} />
                      <Tab label="Generic" className={classes.ExercisesTabNavButton} {...a11yProps('ExercisesTab', 4)} />
                    </Tabs>

                  </AppBar>
                  <TabPanel value={this.state.ExercisesTabValue} index={0} name='ExercisesAssessment'>
                    {this.renderProgramforTab([14,4], ["MW","REF","TT","TB","ACC"], [1,2,3], ["A","B"])}
                  </TabPanel>

                  <TabPanel value={this.state.ExercisesTabValue} index={1} name='ExercisesAssessment'>
                  {this.renderProgramforTab([3,13,3], ["MW","REF","TT","TB","ACC"], [1,2,3], ["A","B"])}
                  </TabPanel>

                  <TabPanel value={this.state.ExercisesTabValue} index={2} name='ExercisesAssessment'>
                   {this.renderProgramforTab([16,6], ["MW","REF","TT","TB","ACC"], [1,2,3], ["A","B"])}
                  </TabPanel>

                  <TabPanel value={this.state.ExercisesTabValue} index={3} name='ExercisesAssessment'>
                   {this.renderProgramforTab([5,15], ["MW","REF","TT","TB","ACC"], [1,2,3], ["A","B"])}
                  </TabPanel>

                  <TabPanel value={this.state.ExercisesTabValue} index={4} name='ExercisesAssessment'>
                    {this.renderProgramforTab([1,11,2,12,3,13,4,14,5,15,6,16,7,17,8,18,21,22,23,2,25,26,27,28], ["MW","REF","TT","TB","ACC"], [1,2,3], ["A","B"])}
                  </TabPanel>
                </Box>


              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
        <AddExercise
          open={this.state.openAddDialog}
          handleCloseDialog={this.handleCloseDialog}
          templates={templates}
          exerciseInfo={exerciseInfo}
          selectedBiasInfo={{
            stage,
            unit,
            selectedCategory,
            bias,
            selectedTemplate,
          }}
        />
        <PopupDialog
          open={Boolean(deleteInfo)}
          title={
            <>
              Are you sure you want to delete <b>{deleteInfo?.name || ""}</b>{" "}
              Exercise?
            </>
          }
          actionTitle="Delete"
          onAction={this.handleDeleteExercise}
          onCancel={this.resetDelete}
          onClose={this.resetDelete}
          actionBg="red"
        />
      </>
    );
  }

}

const mapStateToProps = (state) => ({ exercises: state.exercises });
const mapDispatchToProps = (dispatch) => ({
  getTemplates: () => dispatch(getTemplates()),
  getExercises: () => dispatch(getExercises()),
  deleteExercise: (id) => dispatch(deleteExercise(id)),
});

export default withStyles(combineStyles(commonStyles, styles))(
  connect(mapStateToProps, mapDispatchToProps)(BiasClassification)
);
