import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { http } from "utils";
import { showMessage } from "./snackBarSlice";

const initialState = {
  exercises: [],
  exerciseInfo: {},
  templates: [],
};

export const exerciseSlice = createSlice({
  name: "exercises",
  initialState,
  reducers: {
    setTemplates: (state, { payload }) => {
      state.templates = payload;
    },
    setExercises: (state, { payload }) => {
      state.exercises = payload;
    },
  },
});

const { reducer, actions } = exerciseSlice;
export const { setTemplates, setExercises } = actions;

export const getTemplates = createAsyncThunk(
  "exercises/getTemplates",
  async (obj, { dispatch, getState }) => {
    http("GET", "api/template/get")
      .then((res) => {
        const { data = [] } = res;
        dispatch(setTemplates(data));
      })
      .catch((err) => {
        dispatch(
          showMessage({
            message: err.message || "Something went wrong",
            type: "error",
          })
        );
      });
  }
);

export const getExercises = createAsyncThunk(
  "exercises/getExercises",
  async (obj, { dispatch, getState }) => {
    http("GET", "admin/exercise/get")
      .then((res) => {
        const { data = [] } = res;
        dispatch(setExercises(data));
      })
      .catch((err) => {
        dispatch(
          showMessage({
            message: err.message || "Something went wrong",
            type: "error",
          })
        );
      });
  }
);

export const getExerciseById = createAsyncThunk(
  "exercises/getExerciseById",
  async ({ id, successCB = () => {} }, { dispatch, getState }) => {
    http("GET", `admin/exercise/get/${id}`)
      .then((res) => {})
      .catch((err) => {
        dispatch(
          showMessage({
            message: err.message || "Something went wrong",
            type: "error",
          })
        );
      });
  }
);

export const deleteExercise = createAsyncThunk(
  "exercises/deleteExercise",
  async ({ id, successCB = () => {} }, { dispatch, getState }) => {
    http("POST", `admin/exercise/delete/${id}`)
      .then((res) => {
        const exercises = [...(getState().exercises.exercises || [])];
        const index = exercises.findIndex((c) => c._id === id);
        if (index !== -1) {
          exercises.splice(index, 1);
          dispatch(setExercises(exercises));
          successCB();
        }
        dispatch(
          showMessage({
            message: "Exercise Successfully Deleted",
            type: "success",
          })
        );
      })
      .catch((err) => {
        dispatch(
          showMessage({
            message: err.message || "Something went wrong",
            type: "error",
          })
        );
      });
  }
);

export const uploadExerciseImage = createAsyncThunk(
  "exercises/uploadExerciseImage",
  async ({ reqBody, successCB = () => {} }, { dispatch, getState }) => {
    return http("POST", `admin/upload`, reqBody)
      .then((res) => {
        successCB(res.data);
      })
      .catch((err) => {
        dispatch(
          showMessage({
            message: err.message || "Something went wrong",
            type: "error",
          })
        );
      });
  }
);
export const addExercise = createAsyncThunk(
  "exercises/updateExercise",
  async ({ body, successCB = () => {} }, { dispatch, getState }) => {
    http("POST", `admin/exercise/save`, body)
      .then((res) => {
        if ([200, 201].includes(res.code)) {
          const exercises = [...(getState().exercises.exercises || [])];
          exercises.push(res.data);
          dispatch(setExercises(exercises));
          successCB();
        } else {
          dispatch(
            showMessage({
              message: res.message || "Something went wrong",
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          showMessage({
            message: err.message || "Something went wrong",
            type: "error",
          })
        );
      });
  }
);

export const updateExercise = createAsyncThunk(
  "exercises/updateExercise",
  async ({ body, successCB = () => {} }, { dispatch, getState }) => {
    http("POST", `admin/exercise/edit`, body)
      .then((res) => {
        if ([200, 201].includes(res.code)) {
          const exercises = [...(getState().exercises.exercises || [])];
          const index = exercises.findIndex((c) => c._id === body._id);
          if (index !== -1) {
            exercises.splice(index, 1, res.data);
            dispatch(setExercises(exercises));
          }
          successCB();
        } else {
          dispatch(
            showMessage({
              message: res.message || "Something went wrong",
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          showMessage({
            message: err.message || "Something went wrong",
            type: "error",
          })
        );
      });
  }
);

export default reducer;
