import { AddCircle, Close } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React, { Component } from "react";
import BiasSection from "./BiasSection";
import ExerciseForm from "./ExerciseForm";
import commonStyles from "styles/CommonStyle";
import _, { isEqual } from "lodash";
import { connect } from "react-redux";
import {
  addExercise,
  updateExercise,
  uploadExerciseImage,
} from "store/exercisesSlice";
import { isURL, toBase64 } from "utils";

const styles = (theme) => ({
  commonAccordionMain: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  mainContainer: {
    margin: "20px 0",
  },
  headerText: {
    "&.MuiTypography-root": {
      fontSize: 20,
    },
  },
  panelSummary: {
    justifyContent: "space-between",
    alignItems: "center",
    margin: "6px 0",
  },
  "@media (max-width: 767px)": {
    headerText: {
      fontSize: 16,
    },
  },
  commonBoxLayout: {
    padding: 15,
    background: "linear-gradient(to right bottom, #299dca, #29b09b)",
    boxShadow:
      "0 4px 20px 0px rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(233 30 99 / 40%)",
    marginTop: "-40px",
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 3,
    display: "flex",
  },
  commonIconBox: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    "&.MuiButtonBase-root": { fontSize: "0.675rem", lineHeight: "normal" },
  },
  stage: { display: "flex", fontSize: "0.6rem", justifyContent: "center" },
  section: {
    padding: 12,
    borderRadius: 4,
  },
  dialogTitle: {
    "&.MuiDialogTitle-root": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
      margin: "0 0 16px 0",
    },
  },
});

const initialState = {
  exerciseCategories: {
    MW: [],
    REF: [],
    TT: [],
    TB: [],
    ACC: [],
  },
  selectedBias: [],
  selectedCategory: [],
  selectedUnits: [],
  stage: [],
  bias: [],
  stagesBias: [],
  unit: [],
  selectedTemplate: "",
  isSubmitting: false,
  form: { name: "", src: "", springLoad: "" },
};

const initStagesBias = {
  stage1: {
    group: "",
    selectedBias: [],
  },
  stage2: {
    group: "",
    selectedBias: [],
  },
  stage3: {
    group: [],
    selectedBias: [],
  },
};

export class AddExercise extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedCategory, stage, bias, unit, selectedTemplate, form } =
      this.state;
    const biasFromProps = this.props?.selectedBiasInfo;
    const currentBias = {
      selectedCategory,
      stage,
      bias,
      unit,
      selectedTemplate,
    };

    const { exerciseInfo, open } = this.props;
    const isCurrentBiasEmpty = Object.values(currentBias).every(
      (v) => !v.length
    );
    const isFormEmpty = Object.values(form).every((v) => !v || !v.length);

    if (
      open &&
      isCurrentBiasEmpty &&
      !isEqual(biasFromProps, currentBias) &&
      !exerciseInfo
    ) {
      this.setState({ ...biasFromProps });
    }

    if (exerciseInfo && isFormEmpty) {
      const { selectedTemplate } = biasFromProps;
      const {
        name,
        file,
        springLoad,
        bias,
        category: selectedCategory,
        unit,
        stage,
      } = exerciseInfo;

      const selectedBias = bias.map((v) => String(v));

      const stages = _.range(1, stage + 1);
      this.autofillStagesBias(selectedBias, () => {
        this.setState({
          form: { name, src: file, springLoad },
          bias: selectedBias,
          selectedBias,
          selectedCategory,
          selectedUnits: [unit],
          unit: [unit],
          stage: stages,
          selectedTemplate,
        });
      });
    }
  }

  handleCloseDialog = () => {
    this.props.handleCloseDialog();
    this.setState(initialState);
  };
  handleChange = (event) => {
    const { name = "", value = "", files = [] } = event.target;
    let form = { ...this.state.form };
    if (!name) return;
    if (name === "src") {
      form[name] = files[0];
    } else {
      form[name] = value.toUpperCase();
    }
    this.setState({ form });
  };
  handleSubmit = () => {
    const { form, bias, stage, unit, selectedCategory: category } = this.state;
    const { name, src, springLoad } = form;
    const unitSelection = unit.length ? (unit.includes("B") ? "B" : "A") : "";
    const content = {
      name,
      bias,
      stage: stage[stage.length - 1],
      unit: unitSelection,
      category,
      springLoad,
    };

    const updateInfo = (imageId = "") => {
      content.file = imageId;
      if (this.props.exerciseInfo?._id) {
        this.props.updateExercise({
          body: {
            _id: this.props.exerciseInfo?._id,
            ...content,
          },
          successCB: this.handleCloseDialog,
        });
      } else {
        this.props.addExercise({
          body: content,
          successCB: this.handleCloseDialog,
        });
      }
    };

    if (!isURL(src)) {
      const reqBody = {
        file: "",
        imageType: "exercise",
        type: src.type,
      };
      toBase64(src).then((value) => {
        reqBody.file = value;
        this.props.uploadExerciseImage({ reqBody, successCB: updateInfo });
      });
    } else if (isURL(src)) {
      const fileId = src.split("/").pop();
      updateInfo(fileId);
    }
  };

  autofillStagesBias = (selectedBias = [], callback = () => {}) => {
    let stage = [1];
    const FLBias = ["12", "2", "22", "24", "14", "4"];
    const FRBias = ["6", "16", "8", "18", "26", "28"];
    const ELBias = ["11", "1", "13", "3", "23", "21"];
    const ERBias = ["5", "15", "7", "17", "25", "27"];

    let stage1 = {
      group: "",
      selectedBias: [],
    };
    let stage2 = {
      group: "",
      selectedBias: [],
    };
    let stage3 = {
      group: [],
      selectedBias: [],
    };

    // Init Stages Bias
    const mutualFLBias = FLBias.filter((bias) => selectedBias.includes(bias));
    const mutualFRBias = FRBias.filter((bias) => selectedBias.includes(bias));
    const mutualELBias = ELBias.filter((bias) => selectedBias.includes(bias));
    const mutualERBias = ERBias.filter((bias) => selectedBias.includes(bias));

    if (mutualFLBias.length) {
      stage1 = {
        group: "FL",
        selectedBias: mutualFLBias,
      };
      if (mutualFRBias.length) {
        stage2 = {
          group: "FR",
          selectedBias: mutualFRBias,
        };
      }
      if (mutualELBias.length || mutualERBias.length) {
        stage3 = {
          group: ["EL", "ER"],
          selectedBias: [...mutualELBias, ...mutualERBias],
        };
      }
    } else if (mutualFRBias.length) {
      stage1 = {
        group: "FR",
        selectedBias: mutualFRBias,
      };
      if (mutualELBias.length || mutualERBias.length) {
        stage3 = {
          group: ["EL", "ER"],
          selectedBias: [...mutualELBias, ...mutualERBias],
        };
      }
    } else if (mutualELBias.length) {
      stage1 = {
        group: "EL",
        selectedBias: mutualELBias,
      };
      if (mutualERBias.length) {
        stage2 = {
          group: "ER",
          selectedBias: mutualERBias,
        };
      }
    } else if (mutualERBias.length) {
      stage1 = {
        group: "ER",
        selectedBias: mutualERBias,
      };
    }
    const stagesBias = {
      stage1,
      stage2,
      stage3,
    };

    // Init Stage
    if (
      stage1.selectedBias.length &&
      stage2.selectedBias.length &&
      stage3.selectedBias.length
    ) {
      stage = [1, 2, 3];
    } else if (stage1.selectedBias.length && stage3.selectedBias.length) {
      stage = [1, 2, 3];
    } else if (stage1.selectedBias.length && stage2.selectedBias.length) {
      stage = [1, 2];
    } else {
      stage = [1];
    }

    this.setState({ stage, stagesBias }, callback);
  };

  setTemplate = (template) => {
    const { title, detail } = template;
    let { selectedBias: bias, selectedCategory, selectedUnits: unit } = detail;

    const initTemplate = () => {
      if (this.state.selectedTemplate === title) {
        this.setState({
          bias: initialState.bias,
          selectedCategory: initialState.selectedCategory,
          unit,
          selectedBias: initialState.selectedBias,
          selectedUnits: initialState.unit,
          selectedTemplate: "",
        });
      } else {
        this.setState({
          bias,
          selectedCategory,
          unit,
          selectedBias: bias,
          selectedUnits: unit,
          selectedTemplate: title,
        });
      }
    };

    this.autofillStagesBias(bias, initTemplate);
  };

  handleCategoryChange = (category) => {
    if (category) {
      const { selectedCategory } = this.state;
      const categories = [...selectedCategory];
      if (categories.includes(category)) {
        const index = categories.indexOf(category);
        categories.splice(index, 1);
      } else {
        categories.unshift(category);
      }
      this.setState({ selectedCategory: categories });
    }
  };

  updateBias = (bias, group) => {
    let { stage, stagesBias } = this.state;
    if (!bias.length) {
      // Reset pizza on empty selection
      stagesBias = { ...initStagesBias };
    } else if (stage.length === 1) {
      // handle stage1
      stagesBias = { ...stagesBias, stage1: { group, selectedBias: bias } };
    } else if (stage.length === 2) {
      // handle stage2
      if (!stagesBias.stage1.group) {
        // Init first quadrants to stage1
        stagesBias = {
          ...stagesBias,
          stage1: { group, selectedBias: bias },
        };
      } else if (stagesBias.stage1.group === group) {
        // add bias to stage1
        if (!stagesBias.stage2.group) {
          stagesBias = {
            ...stagesBias,
            stage1: { group, selectedBias: bias },
          };
        } else {
          const stage1Bias = bias.filter(
            (item) => !stagesBias.stage2.selectedBias.includes(item)
          );
          stagesBias = {
            ...stagesBias,
            stage1: { group, selectedBias: stage1Bias },
          };
        }
      } else if (
        stagesBias.stage1.group &&
        stagesBias.stage1.group !== group &&
        !stagesBias.stage2.group
      ) {
        // Init secound quadrants for stage2
        let nextStage2Group = "";
        switch (stagesBias.stage1.group) {
          case "ER":
            nextStage2Group = "EL";
            break;
          case "EL":
            nextStage2Group = "ER";
            break;
          case "FL":
            nextStage2Group = "FR";
            break;
          case "FR":
            nextStage2Group = "FL";
            break;
          default:
            nextStage2Group = "";
        }

        if (group === nextStage2Group) {
          // add bias to stage2
          const stage2Bias = bias.filter(
            (item) => !stagesBias.stage1.selectedBias.includes(item)
          );
          stagesBias = {
            ...stagesBias,
            stage2: { group, selectedBias: stage2Bias },
          };
        }
      } else if (stagesBias.stage2.group === group) {
        // add bias to stage2
        const stage2Bias = bias.filter(
          (item) => !stagesBias.stage1.selectedBias.includes(item)
        );
        stagesBias = {
          ...stagesBias,
          stage2: { group, selectedBias: stage2Bias },
        };
      }
    } else if (stage.length === 3) {
      // handle stage3
      if (!stagesBias.stage1.group) {
        // Init first quadrants to stage1
        stagesBias = {
          ...stagesBias,
          stage1: { group, selectedBias: bias },
        };
      } else if (stagesBias.stage1.group === group) {
        // add bias to stage1
        let stage1Bias = [...bias];

        // clean state2 bias
        if (stagesBias.stage2.selectedBias.length) {
          stage1Bias = stage1Bias.filter(
            (item) => !stagesBias.stage2.selectedBias.includes(item)
          );
        }
        // clean state3 bias
        if (stagesBias.stage3.selectedBias.length) {
          stage1Bias = stage1Bias.filter(
            (item) => !stagesBias.stage3.selectedBias.includes(item)
          );
        }

        stagesBias = {
          ...stagesBias,
          stage1: { group, selectedBias: stage1Bias },
        };
      } else if (
        stagesBias.stage1.group &&
        stagesBias.stage1.group !== group &&
        !stagesBias.stage2.group
      ) {
        // Init secound quadrants for stage2/hemisphere for stage 3
        let nextStage2Group = "";
        switch (stagesBias.stage1.group) {
          case "ER":
            nextStage2Group = "EL";
            break;
          case "EL":
            nextStage2Group = "ER";
            break;
          case "FL":
            nextStage2Group = "FR";
            break;
          case "FR":
            nextStage2Group = "FL";
            break;
          default:
            nextStage2Group = "";
        }

        if (group === nextStage2Group) {
          // Init quadrants to stage2
          let stage2Bias = bias.filter(
            (item) => !stagesBias.stage1.selectedBias.includes(item)
          );

          // clean stage3 bias
          if (stagesBias.stage3.selectedBias.length) {
            stage2Bias = stage2Bias.filter(
              (item) => !stagesBias.stage3.selectedBias.includes(item)
            );
          }

          stagesBias = {
            ...stagesBias,
            stage2: { group, selectedBias: stage2Bias },
          };
        } else {
          let stage3Bias = bias.filter(
            (item) => !stagesBias.stage1.selectedBias.includes(item)
          );
          const groupUpdate = [...stagesBias.stage3.group];
          if (!groupUpdate.includes(group)) {
            groupUpdate.push(group);
          }
          // Init hemisphere for stage 3
          stagesBias = {
            ...stagesBias,
            stage3: { group: groupUpdate, selectedBias: stage3Bias },
          };
        }
      } else if (stagesBias.stage2.group === group) {
        // add bias to stage2
        let stage2Bias = bias.filter(
          (item) => !stagesBias.stage1.selectedBias.includes(item)
        );
        if (stagesBias.stage3.selectedBias.length) {
          stage2Bias = stage2Bias.filter(
            (item) => !stagesBias.stage3.selectedBias.includes(item)
          );
        }
        stagesBias = {
          ...stagesBias,
          stage2: { group, selectedBias: stage2Bias },
        };
      } else {
        // add bias to stage3

        if (!stagesBias.stage3.group.includes(group)) {
          // include group
          let groupUpdate = [...stagesBias.stage3.group];
          groupUpdate.push(group);
        }

        // clean stage1 bias
        let stag3Bias = bias.filter(
          (item) => !stagesBias.stage1.selectedBias.includes(item)
        );

        // clean stage2 bias
        if (stagesBias.stage2.selectedBias.length) {
          stag3Bias = stag3Bias.filter(
            (item) => !stagesBias.stage2.selectedBias.includes(item)
          );
        }
        stagesBias = {
          ...stagesBias,
          stage3: { group, selectedBias: stag3Bias },
        };
      }
    }
    this.setState({ bias, stagesBias });
  };

  handleStageClick = (selectedStage) => {
    let { bias, stage, stagesBias } = this.state;
    // if (includes(stage, +selectedStage)) {
    //   const index = stage.indexOf(selectedStage);
    //   stage.splice(index, 1);
    // } else {
    //   stage.push(+selectedStage);
    // }
    if (selectedStage === "1") {
      if (stage.includes(2)) {
        bias = [...stagesBias?.stage1?.selectedBias];
        stagesBias = {
          ...stagesBias,
          stage2: {
            group: "",
            selectedBias: [],
          },
          stage3: {
            group: "",
            selectedBias: [],
          },
        };
      }
      stage = [1];
    } else if (selectedStage === "2") {
      if (stage.includes(2)) {
        bias = [...stagesBias?.stage1?.selectedBias];
        stagesBias = {
          ...stagesBias,
          stage2: {
            group: "",
            selectedBias: [],
          },
          stage3: {
            group: "",
            selectedBias: [],
          },
        };
        stage = [1];
      } else {
        stage = [1, 2];
      }
    } else if (selectedStage === "3") {
      if (stage.includes(3)) {
        bias = [
          ...stagesBias?.stage1?.selectedBias,
          ...stagesBias?.stage2?.selectedBias,
        ];
        stagesBias = {
          ...stagesBias,
          stage3: {
            group: "",
            selectedBias: [],
          },
        };
        stage = [1, 2];
      } else {
        stage = [1, 2, 3];
      }
    }
    this.setState({ bias, stage, stagesBias });
  };

  handleUnitClick = (selectedUnit) => {
    const { unit } = this.state;
    const units = [...unit];
    if (units.includes(selectedUnit)) {
      const index = units.indexOf(selectedUnit);
      units.splice(index, 1);
    } else {
      units.push(selectedUnit);
    }
    this.setState({ unit: units });
  };

  handleSwitchChange = (event) => {
    const { name } = event.target;
    this.setState((state) => ({ [name]: !state[name] }));
  };

  render() {
    const { templates, classes } = this.props;

    let { stage = [], bias = [], stagesBias = {} } = this.state;

    let validGroupList = [];
    let invalidGroupList = [],
      groupList = [];
    if (bias.length && stage.length === 1) {
      const selectedGroup = stagesBias?.stage1?.group;
      groupList.forEach((group) => {
        if (group !== selectedGroup) {
          invalidGroupList.push(group);
        } else {
          validGroupList.push(group);
        }
      });
    } else if (bias.length && stage.length === 2) {
      const stageOneGroup = stagesBias?.stage1?.group;
      if (stageOneGroup === "FR" || stageOneGroup === "FL") {
        validGroupList = ["FR", "FL"];
        invalidGroupList = ["ER", "EL"];
      } else {
        validGroupList = ["ER", "EL"];
        invalidGroupList = ["FR", "FL"];
      }
    } else {
      validGroupList = ["FR", "ER", "EL", "FL"];
      invalidGroupList = [];
    }

    const {
      isSubmitting,
      form: { name, src },
      unit,
      selectedCategory: category,
      selectedTemplate,
    } = this.state;
    const requiredFields = {
      name,
      src,
      bias,
      stage,
      unit,
      category,
      selectedTemplate,
    };

    const isValid = Object.values(requiredFields).every((c) =>
      Array.isArray(c) ? c.length : Boolean(c)
    );
    return (
      <>
        <Dialog
          open={Boolean(this.props.open)}
          onClose={this.handleCloseDialog}
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle className={classes.dialogTitle}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <AddCircle sx={{ mr: 1 }} />
                <Typography variant="h5">Add Exercise</Typography>
              </div>
              <Close
                onClick={this.handleCloseDialog}
                style={{ cursor: "pointer" }}
              />
            </div>
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              alignItems={"flex-start"}
              justifyContent={"space-between"}
              spacing={0}
            >
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Typography variant="h6" sx={{ mb: 1 }}>
                  Bias Classification
                </Typography>
                <Divider flexItem />
                <div className={classes.section}>
                  <BiasSection
                    isAddExercise
                    templates={templates}
                    state={this.state}
                    updateBias={this.updateBias}
                    validGroupList={validGroupList}
                    invalidGroupList={invalidGroupList}
                    handleStageClick={this.handleStageClick}
                    handleUnitClick={this.handleUnitClick}
                    setTemplate={this.setTemplate}
                    handleCategoryChange={this.handleCategoryChange}
                  />
                </div>
              </Grid>
              <Divider orientation="vertical" flexItem />
              <Grid
                item
                xs={12}
                sm={12}
                md={7}
                lg={7}
                xl={7}
                alignItems={"flex-start"}
              >
                <Typography variant="h6" sx={{ mb: 1 }}>
                  Exercise Details
                </Typography>
                <Divider flexItem />
                <div className={classes.section}>
                  <ExerciseForm
                    form={this.state.form}
                    handleChange={this.handleChange}
                  />
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              component="span"
              onClick={this.handleSubmit}
              disabled={!isValid || isSubmitting}
            >
              {isSubmitting ? <CircularProgress size={24} /> : "Submit"}
            </Button>
            <Button
              variant="outlined"
              component="span"
              onClick={this.handleCloseDialog}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state) => ({ exercises: state.exercises });
const mapDispatchToProps = (dispatch) => ({
  updateExercise: (data) => dispatch(updateExercise(data)),
  addExercise: (data) => dispatch(addExercise(data)),
  uploadExerciseImage: (data) => dispatch(uploadExerciseImage(data)),
});

export default withStyles(
  styles,
  commonStyles
)(connect(mapStateToProps, mapDispatchToProps)(AddExercise));
