import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  CardActions,
  CardHeader,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
} from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import useWidth from "hooks/useWidth";
import Logo from "images/logo-old.png";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { checkAuth, loginUser } from "store/authSlice";
import useStyles from "./FormStyle";

const Login = (props) => {
  const navigate = useNavigate();
  const width = useWidth();
  const dispatch = useDispatch();
  const [form, setForm] = useState({ email: "", password: "" });
  const { email, password } = form;
  const [showPassword, setShowPassword] = useState(false);
  useEffect(() => {
    const isAuthenticated = dispatch(checkAuth());
    if (isAuthenticated) {
      navigate("/");
    }
  }, [navigate, dispatch]);

  // function validate() {
  //   const validCreds = {
  //     email: "craig.phillips@clinicalpilates.com",
  //     password: "crud64",
  //   };

  //   return email === validCreds.email && password === validCreds.password;
  // }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const successCB = () => {
      navigate("/");
    };
    dispatch(loginUser(form, successCB));
  };
  const classes = useStyles();

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} sm={12} md={6}>
        <div className={classes.loginLeftContent}>
          <div>
            <Link to="/">
              <img alt="Logo" src={Logo} className={classes.brandLogo} />
            </Link>
          </div>
          <div>
            <Card className={classes.loginFormCard}>
              <CardHeader title="Member Login" />
              <CardActions>
                <form className={classes.container} onSubmit={handleSubmit}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <TextField
                        id="email"
                        label="Email"
                        name="email"
                        className={classes.textField}
                        value={email}
                        onChange={handleChange}
                        variant="outlined"
                        required
                        autoFocus
                        type="email"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="filled-adornment-password"
                        className={clsx(classes.margin, classes.textField)}
                        variant="outlined"
                        type={showPassword ? "text" : "password"}
                        label="Password"
                        name="password"
                        value={password}
                        required
                        autoComplete="new-password"
                        onChange={handleChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment variant="filled" position="end">
                              <IconButton
                                aria-label="Toggle password visibility"
                                onClick={() => {
                                  setShowPassword((prev) => !prev);
                                }}
                                style={{ padding: 0 }}
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} container justifyContent="flex-end">
                      <Link to="/forgot" style={{ textDecoration: "none" }}>
                        <Typography
                          variant="subtitle1"
                          color="primary"
                          className={classes.forgotText}
                        >
                          Forgot Password ?
                        </Typography>
                      </Link>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        variant="outlined"
                        size="large"
                        color="primary"
                        fullWidth={true}
                        //   disabled={loading}
                        className={classes.loginBtn}
                      >
                        {false ? <CircularProgress size={24} /> : "Login"}
                      </Button>
                      {/* {false && (
                                                <CircularProgress
                                                    size={24}
                                                    className={classes.buttonProgress}
                                                />
                                            )} */}
                    </Grid>
                  </Grid>
                </form>
              </CardActions>
            </Card>
          </div>
        </div>
      </Grid>
      {["sm", "md", "lg", "xl"].includes(width) && (
        <Grid item xs={12} sm={12} md={6}>
          <div className={classes.clinicalImage}>&nbsp;</div>
        </Grid>
      )}
    </Grid>
  );
};

export default Login;
