import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  button: {
    // margin: theme.spacing(1),
    width: "100%",
    padding: 8,
    "&.MuiButtonBase-root": {
      fontSize: "0.7rem",
    },
  },
  input: {
    display: "none",
  },
  margin: {
    margin: theme.spacing(2),
  },
  badgeRoot: {
    "&.MuiBadge-root": {
      display: "flex",
      flexDirection: "column",
    },
  },
  "@media (max-width: 1600px)": {
    pizzaSectionBtn: {
      maxWidth: "100%",
      flexBasis: "100%",
    },
    button: {
      fontSize: `0.65rem`,
    },
  },
  "@media (max-width: 1366px)": {
    pizzaSectionBtn: {
      maxWidth: "100%",
      flexBasis: "100%",
    },
  },
  "@media (max-width: 1280px)": {
    pizzaSectionBtn: {
      maxWidth: "100%",
      flexBasis: "100%",
    },
  },
  "@media (max-width: 1024px)": {
    pizzaSectionBtn: {
      maxWidth: "100%",
      flexBasis: "100%",
    },
    // preScreenPizzaSectionBtn: {
    //   maxWidth: '100%',
    //   flexBasis: '100%'
    // }
  },
  "@media (max-width: 991px)": {
    pizzaSectionBtn: {
      maxWidth: "100%",
      flexBasis: "100%",
    },
  },
  "@media (max-width: 767px)": {
    pizzaSectionBtn: {
      maxWidth: "100%",
      flexBasis: "100%",
    },
  },
  "@media (max-width: 567px)": {
    pizzaSectionBtn: {
      maxWidth: "100%",
      flexBasis: "100%",
    },
  },
});

class Category extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }
  renderCategoryName(name) {
    switch (name) {
      case "MW":
        return "Matwork";
      case "REF":
        return "Reformer";
      case "TT":
        return "Trapeze";
      case "TB":
        return "Theratube";
      case "ACC":
        return "Accessories";
      default:
        return name;
    }
  }
  render() {
    const {
      // prescreenMode,
      classes,
      name,
      selected,
      badge,
      handleCategoryChange,
      prescreenMode,
    } = this.props;
    return (
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={6}
        className={clsx(classes.pizzaSectionBtn, {
          [classes.preScreenPizzaSectionBtn]: prescreenMode,
        })}
      >
        {badge ? (
          <Badge
            classes={{ root: classes.badgeRoot }}
            className={classes.badgeRoot}
            key={name}
            color="primary"
            badgeContent={badge}
          >
            <Button
              className={classes.button}
              color={selected ? "primary" : "inherit"}
              onClick={() => handleCategoryChange(name)}
            >
              {this.renderCategoryName(name)}
            </Button>
          </Badge>
        ) : (
          <Button
            className={classes.button}
            color={selected ? "primary" : "inherit"}
            onClick={() => handleCategoryChange(name)}
          >
            {this.renderCategoryName(name)}
          </Button>
        )}
      </Grid>
    );
  }
}

Category.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Category);
