import React from "react";
// import FormControl from "@mui/material/FormControl";
// import FormGroup from "@mui/material/FormGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import includes from "lodash/includes";
// import { makeStyles } from "@mui/material";

// const useStyles = makeStyles({
//   root: {
//     // display: 'flex',
//     // justifyContent: 'center',
//     // alignItems: 'center'
//   },
//   formGroup: {
//     display: "flex",
//     flexDirection: "row",
//     justifyContent: "center",
//     // alignItems: 'center'
//   },
// });

export default React.memo(function Stage({ activeStage, handleClick }) {
  // const classes = useStyles();
  const stage1 = includes(activeStage, 1);
  const stage2 = includes(activeStage, 2);
  const stage3 = includes(activeStage, 3);
  function handleChange(event) {
    const { value } = event.currentTarget;
    handleClick(value);
  }
  return (
    <>
      {/* <div className={classes.root}>
        <div className={classes.formGroup}> */}
      <Button
        color={stage1 ? "primary" : "inherit"}
        onClick={(e) => handleChange(e)}
        value="1"
        variant="text"
      >
        Stage 1
      </Button>
      <Button
        color={stage2 ? "primary" : "inherit"}
        onClick={(e) => handleChange(e)}
        value="2"
        variant="text"
      >
        Stage 2
      </Button>
      <Button
        color={stage3 ? "primary" : "inherit"}
        onClick={(e) => handleChange(e)}
        value="3"
        variant="text"
      >
        Stage 3
      </Button>
      {/* </div> */}
      {/* <FormControl component="fieldset">
        <FormLabel component="legend">Assign responsibility</FormLabel>    //Comment this after Reverting back
        <FormGroup className={classes.formGroup}>
          <FormControlLabel
            control={
              <Checkbox
                checked={stage1}
                onChange={handleChange}
                value='1'
                color='primary'
              />
            }
            label='Stage 1'
          />
          
          <FormControlLabel
            control={
              <Checkbox
                checked={stage2}
                onChange={handleChange}
                value="2"
                color="primary"
              />
            }
            label="Stage 2"
          />
         
          <FormControlLabel
            control={
              <Checkbox
                checked={stage3}
                onChange={handleChange}
                value="3"
                color="primary"
              />
            }
            label="Stage 3"
          />
          
        </FormGroup>
      </FormControl> */}
      {/* </div> */}
    </>
  );
});
