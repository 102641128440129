import { Cancel, PhotoSizeSelectActual } from "@mui/icons-material";
import { TextField, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useRef } from "react";
import { isURL, toBlob } from "utils";

const useStyles = makeStyles((theme) => ({
  uploadContainer: {
    color: "#9d9d9d",
    height: 280,
    width: "100%",
    cursor: "default",
    outline: "2px dashed #9d9d9d",
    outlineOffset: -3,
  },
  uploadArea: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    height: "100%",
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    height: "100%",
  },
  removeImgBtn: {
    cursor: "pointer",
    position: "absolute",
    top: 4,
    right: 4,
  },
}));

function ExerciseForm({ handleChange = () => {}, form = {} }) {
  const classes = useStyles();
  const fileRef = useRef(null);

  const handleRemoveImage = () => {
    fileRef.current.value = null;
    const file = { target: { name: "src", files: [] } };
    handleChange(file);
  };

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <TextField
            label="Name"
            name="name"
            value={form.name}
            onChange={handleChange}
            fullWidth
            required
          />
        </Grid>
        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <TextField
            label="Spring Load"
            name="springLoad"
            value={form.springLoad}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <div className={classes.uploadBtn}>
            <label htmlFor="exercise-image">
              <input
                ref={fileRef}
                accept="image/*"
                id="exercise-image"
                multiple
                type="file"
                hidden
                name="src"
                onChange={handleChange}
              />
            </label>
            <div className={classes.uploadContainer}>
              {!form.src ? (
                <div
                  className={classes.uploadArea}
                  onClick={() => fileRef.current.click()}
                >
                  <PhotoSizeSelectActual sx={{ fontSize: 48 }} />
                  Choose Image to Upload
                </div>
              ) : (
                <div className={classes.imageContainer}>
                  <Cancel
                    onClick={handleRemoveImage}
                    className={classes.removeImgBtn}
                  />
                  <img
                    src={isURL(form.src) ? form.src : toBlob(form.src)}
                    alt="exercise"
                    style={{ height: "100%" }}
                  />
                </div>
              )}
            </div>
          </div>{" "}
        </Grid>
      </Grid>
    </div>
  );
}

export default ExerciseForm;
