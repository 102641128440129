import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#0b9ea4",
    },
    secondary: {
      main: "#f7f7f7",
    },
    textPrimary: {
      main: "#3C4858",
    },
  },
});

export default theme;
