import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { http } from "utils";
import { showMessage } from "./snackBarSlice";

// const demoVideos = [
//   {
//     id: 0,
//     name: "Save to iPad home screen",
//     thumbnailImage: "../images/demo-videos/save-to-home-3.gif",
//     videoLink: "https://youtu.be/cXUHG2g4w7w",
//   },
//   {
//     id: 1,
//     name: "Create a new program from template",
//     thumbnailImage: "../images/demo-videos/how-to-video-prgm.gif",
//     videoLink: "https://youtu.be/FWYdRFgXseU",
//   },
//   {
//     id: 3,
//     name: "Add Subjective History",
//     thumbnailImage: "../images/demo-videos/assessment.gif",
//     videoLink: "https://youtu.be/Nkqfb_mcPp8",
//   },
// ];

const initialState = {
  videos: [],
  isUploading: false,
  isLoading: false,
};

export const htvSlice = createSlice({
  name: "how-to-videos",
  initialState,
  reducers: {
    addVideo: (state, action) => {
      state.videos = action.payload;
    },
    updateVideo: (state) => {
      state = initialState;
    },
    removeVideo: (state, action) => {
      state.videos = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setUploading: (state, action) => {
      state.isUploading = action.payload;
    },
  },
});

const { addVideo, removeVideo, setLoading, setUploading } = htvSlice.actions;

export const getVideos = createAsyncThunk(
  "how-to-videos/getVideos",
  async (obj, { dispatch, getState }) => {
    dispatch(setLoading(true));
    http("GET", "admin/videos")
      .then((res) => {
        const { data = [] } = res;
        dispatch(setLoading(false));
        dispatch(addVideo([...data]));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(
          showMessage({
            message: err.message || "Something went wrong",
            type: "error",
          })
        );
      });
  }
);

export const uploadThumbnail = createAsyncThunk(
  "how-to-videos/uploadThumbnail",
  async ({ reqBody, successCB = () => {} }, { dispatch, getState }) => {
    dispatch(setUploading(true));
    return http("POST", `admin/upload`, reqBody)
      .then((res) => {
        successCB(res.data);
      })
      .catch((err) => {
        dispatch(setUploading(false));
        dispatch(
          showMessage({
            message: err.message || "Something went wrong",
            type: "error",
          })
        );
      });
  }
);

export const saveVideo = createAsyncThunk(
  "how-to-videos/saveVideo",
  async ({ content, successCB = () => {} }, { dispatch, getState }) => {
    dispatch(setUploading(true));
    http("POST", `admin/video/save`, content)
      .then((res) => {
        const { videos } = getState().howToVideos;
        const { data } = res;
        data.thumbnailImage = `https://cpmate-staging.s3.ap-southeast-2.amazonaws.com/Images/Thumbnail/${data.thumbnailImage}`;
        dispatch(addVideo([...videos, data]));
        dispatch(setUploading(false));
        successCB();
      })
      .catch((err) => {
        dispatch(setUploading(false));
        dispatch(
          showMessage({
            message: err.message || "Something went wrong",
            type: "error",
          })
        );
      });
  }
);

export const deleteVideo = createAsyncThunk(
  "how-to-videos/deleteVideo",
  async (id, { dispatch, getState }) => {
    const { videos: prev } = getState().howToVideos;
    const videos = [...prev];
    return http("GET", `admin/video/delete/${id}`)
      .then((res) => {
        if (res.code === 200) {
          const index = videos.findIndex((i) => i._id === id);
          if (index !== -1) {
            videos.splice(index, 1);
            dispatch(removeVideo([...videos]));
          }
          dispatch(
            showMessage({
              message: "Video Deleted Successfully",
              type: "success",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          showMessage({
            message: err.message || "Something went wrong",
            type: "error",
          })
        );
      });
  }
);

// Action creators are generated for each case reducer function

export default htvSlice.reducer;
