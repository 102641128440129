import axios from "axios";
import {
  getAccessToken,
  removeAccessToken,
  removeUserInfo,
} from "store/authSlice";
// import { showMessage } from "store/snackBarSlice";

const accessToken = getAccessToken();

export const http = (
  method = "get",
  request = "",
  payload = {},
  token = true,
  config = {}
) => {
  const url = process.env.REACT_APP_HOST + request;
  const options = {
    method,
    headers: { Authorization: accessToken ? `jwt ${accessToken}` : "" },
    url,
    data: payload,
    ...config,
  };

  return new Promise((resolve, reject) => {
    axios(options, payload)
      .then((res) => {
        const { code } = res.data;
        if (code === 401) {
          removeAccessToken();
          removeUserInfo();
          reject(res.data);
        } else {
          resolve(res.data);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          reject(error.response.data);
        } else {
          console.error(error);
        }
      });
  });
};

export const isURL = (str) =>
  typeof str === "string" ? str.match(/^https:/g) : false;

export function isImageOrVideo(ext = "") {
  switch (ext.toLowerCase()) {
    case "jpg":
    case "jpeg":
    case "gif":
    case "bmp":
    case "png":
    case "m4v":
    case "avi":
    case "mpg":
    case "mp4":
    case "mpeg4":
      return true;
    default:
      return false;
  }
}

export const toBlob = (file) => (file ? URL.createObjectURL(file) : null);
export const toBase64 = (file) => {
  return new Promise((resolve) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result);
    };
    // reader.onerror = function (error) {
    //   reject(error);
    // };
  });
};

export function combineStyles(...styles) {
  return function CombineStyles(theme) {
    const outStyles = styles.map((arg) => {
      // Apply the "theme" object for style functions.
      if (typeof arg === "function") {
        return arg(theme);
      }
      // Objects need no change.
      return arg;
    });

    return outStyles.reduce((acc, val) => Object.assign(acc, val));
  };
}

export const detectmob = function () {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  } else {
    return false;
  }
};
