import React, { useEffect, useState } from "react";
import { VideoCall } from "@mui/icons-material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import { makeStyles } from "@mui/styles";
import AddVideo from "./AddVideo";
import VideoGrid from "./VideoGrid";
import { getVideos } from "store/htvSlice";
import { useDispatch } from "react-redux";

const useStyles = makeStyles({
  commonAccordionMain: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  headerText: {
    "&.MuiTypography-root": {
      fontSize: 20,
    },
  },
  panelSummary: {
    justifyContent: "space-between",
    alignItems: "center",
    "&.MuiAccordionSummary-root.Mui-expanded": {
      minHeight: 48,
    },
    "&.MuiAccordionSummary-content.Mui-expanded": {
      margin: "12px 0",
    },
  },
  "@media (max-width: 767px)": {
    headerText: {
      fontSize: 16,
    },
  },
  commonBoxLayout: {
    padding: 15,
    background: "linear-gradient(to right bottom, #299dca, #29b09b)",
    boxShadow:
      "0 4px 20px 0px rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(233 30 99 / 40%)",
    marginTop: "-40px",
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 3,
    display: "flex",
  },
  commonIconBox: {
    display: "flex",
    alignItems: "center",
  },
});

function HowToVideos() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    dispatch(getVideos());
  }, [dispatch]);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          classes={{
            expanded: classes.panelSummary,
          }}
        >
          <div className={classes.commonAccordionMain}>
            <div className={classes.commonIconBox}>
              <div className={classes.commonBoxLayout}>
                <VideoLibraryIcon color="secondary" />
              </div>
              <Typography
                variant="body2"
                color="primary"
                className={classes.headerText}
              >
                How to videos
              </Typography>
            </div>
            <div>
              <IconButton
                aria-label="Add Video"
                style={{ height: 28, width: 28, padding: 4 }}
                onClick={(event) => {
                  handleOpenDialog();
                  event.stopPropagation();
                }}
              >
                <Tooltip title="Add Video" aria-label="Add Video">
                  <VideoCall color="primary" fontSize="large" />
                </Tooltip>
              </IconButton>
            </div>
          </div>
          {/* </Paper> */}
        </AccordionSummary>
        <AccordionDetails>
          <VideoGrid />
        </AccordionDetails>
      </Accordion>
      <AddVideo open={openDialog} handleClose={handleCloseDialog} />
    </>
  );
}

export default HowToVideos;
