import { configureStore } from "@reduxjs/toolkit";
import auth from "./authSlice";
import howToVideos from "./htvSlice";
import exercises from "./exercisesSlice";
import snackBarReducer from "./snackBarSlice";

const store = configureStore({
  reducer: {
    auth,
    notifications: snackBarReducer,
    howToVideos,
    exercises,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  devTools: process.env.NODE_ENV !== "production",
});

export default store;
